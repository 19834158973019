.hide {
  display: none !important;
}
.autoFilter {
  margin: 0 0.9rem;
}
.markaNaslov {
  font-size: 0.9rem;
  color: #5a5a65;
  padding-bottom: 0.5rem;
}
.backMobileSaerch {
  width: 30px;
  height: 30px;
}
.css-13cymwt-control {
  border: none !important;
  font-size: 0.9rem !important;
  background-color: #f2f3f5 !important;
}

.markaDiv {
  padding-bottom: 0.5rem;
}
.h1PopuniDetalje {
  font-size: 1.2rem;
  font-weight: 700;
}
.dodatneInformacije {
  font-size: 0.9rem;
  padding-bottom: 0.7rem;
  font-weight: 600;
  padding-top: 1.2rem;
}
.osnovneInformacije {
  font-size: 0.9rem;
  padding-bottom: 0.3rem;
  font-weight: 600;
}

.stanjeSelect,
.karoserijaSelect,
.materijalSelect,
.drzavaSelect,
.gradSelect,
.ostecenjeSelect,
.modelSelect,
.brCilindaraSelect,
.gorivoSelect,
.godisteSelect,
.brojOsovinaSelect,
.tabliceSelect,
.regSelect,
.brojMotoraSelect,
.pogonSelect,
.tipSelect,
.mjenjacSelect,
.bojaSelect,
.brSatiSelect,
.uslugeSelect,
.brVrataSelect,
.porijekloSelect,
.brMjenjacaSelect,
.nosivostSelect,
.brSJedistaSelect,
.zamjenaSelect,
.markaSelect {
  outline: none;
  width: -webkit-fill-available;
  background-color: #ffffff;
  border: 1px solid #d0d0d07a;
  border-radius: 7px;
  height: 2.5rem;
  font-size: 0.8rem;
  color: #7a7a7a;
  padding-left: 7px;
  margin-bottom: 0.5rem;
}

.hpInput,
.kmInput,
.ksInput,
.duzinaInput,
.sirinaInput,
.cijenaInput,
.brSatiInput,
.cijenaInput,
.duzinaInputDodajOglas,
.sirinaInputDodajOglas,
.cijenaDodajOglasInput,
.kwInput,
.godisteInput,
.cm3Input {
  outline: none;
  width: -webkit-fill-available;
  background-color: #f2f3f5;
  border: none;
  border-radius: 7px;
  height: 2.5rem;
  font-size: 0.9rem;
  padding-left: 10px;
  color: #5a5a65;
  font-weight: 600;
}
.atributi select,
.atributi input {
  border: 1px solid #d0d0d07a;
  color: grey;
}
.naslovInput,
.inputModelKamion,
.brSatiDodajOglas,
.inputMarka,
.oznakaInput {
  width: -webkit-fill-available;
  border: 1px solid #d0d0d07a;
  border-radius: 7px;
  height: 2.5rem;
  font-size: 0.8rem;
  color: rgb(175, 175, 175);
  outline: none;
  font-weight: 500;
  padding-left: 10px;
  margin-bottom: 0.5rem;
}
.dodatneInfroContainer .horizontal-line {
  margin-top: 1.2rem !important;
}
.relative {
  position: relative;
}
.obavezniPodatak {
  position: absolute;
  color: red;
  top: -9px;
  right: 0px;
  z-index: 5;
  font-size: 1.3rem;
}
.objasnjenjeDiv {
  display: flex;
  padding-top: 1rem;
  gap: 2vw;
}
.red {
  color: red;
  font-size: 1.3rem;
}
.tekstOprema {
  font-weight: 500;
  font-size: 0.7rem;
  color: #4f4f50;
}
.divOprema {
  gap: 7px;
  display: flex;
  align-items: center;
}
.dodatnaOprema.dodatnaOprema {
  width: -webkit-fill-available;
  row-gap: 0.7rem;
  gap: 0.5rem;
  display: grid;

  grid-template-columns: 50% 50%;
}
.unutrasnjaOpremaNaslov {
  font-size: 0.9rem;
  padding-bottom: 0.7rem;
  font-weight: 600;
  padding-top: 1.2rem;
}
.enterijer {
  width: -webkit-fill-available;
  row-gap: 0.7rem;
  gap: 0.5rem;
  display: grid;

  grid-template-columns: 50% 50%;
}
.dodatneInfroContainer {
}
.naslovKategorije {
  font-size: 0.9rem;
  font-weight: 600;
  color: #5a5a65;
}
.motorista {
  width: 5rem;
}
#hide {
  display: none;
}
.cm3Input {
  position: relative;
}
.error {
  border: 2px solid red !important;
  margin-bottom: 1rem !important;
}
.errorMess {
  font-size: 0.7rem;
  color: red;
}
.dodajOglasNaslovKategorija {
  width: 2rem;
}
.duzinaInputDodajOglas,
.sirinaInputDodajOglas {
  outline: none;
  margin-bottom: 0.5rem;
  padding-left: 7px;
}
.marginNula {
  margin-top: 0 !important;
}
.sacuvaniOglasiHeader .back {
  margin: 0.9rem;
}
.nemaSacuvanihOglasa {
  color: #5a5a65;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
}
.headerDodajOglas {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.naslovDodajOglas {
  font-size: 1rem;
  font-weight: 600;
  color: #2d2d2d;
}
@media (min-width: 777px) {
  .atributi .oznakaInput,
  .atributi .naslovInput,
  .atributi .stanjeSelect,
  .atributi .karoserijaSelect,
  .atributi .materijalSelect,
  .atributi .drzavaSelect,
  .atributi .gradSelect,
  .atributi .ostecenjeSelect,
  .atributi .modelSelect,
  .atributi .brCilindaraSelect,
  .atributi .gorivoSelect,
  .atributi .godisteSelect,
  .atributi .brojOsovinaSelect,
  .atributi .tabliceSelect,
  .atributi .regSelect,
  .atributi .brojMotoraSelect,
  .atributi .pogonSelect,
  .atributi .tipSelect,
  .atributi .mjenjacSelect,
  .atributi .bojaSelect,
  .atributi .brSatiSelect,
  .atributi .uslugeSelect,
  .atributi .brVrataSelect,
  .atributi .porijekloSelect,
  .atributi .brMjenjacaSelect,
  .atributi .nosivostSelect,
  .atributi .brSJedistaSelect,
  .atributi .zamjenaSelect,
  .atributi .markaSelect {
    background-color: #f2f3f5;
    outline: none;
  }
  .h1DivDodajOglas,
  .osnovneInformacije {
    grid-column: span 3;
  }
  .automobiliDodajOglasContainer .butoniPretraga {
    grid-template-columns: 1fr;
  }
  .dodatnaOprema.dodatnaOprema,
  .enterijer {
    width: -webkit-fill-available;
    row-gap: 0.7rem;
    gap: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .atributi {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5rem;
  }
  .razgovoriContainer .headerDodajOglas {
    display: flex !important;
  }
  #buttoniContainer {
    display: grid;

    column-gap: 8px;
  }
  .stilovi,
  .nastavi {
    width: 357.5px;
  }
  div[class="pretragaFilter pretragaAutmomobiliContainer"] .cijenaInput {
    margin-bottom: 0;
  }
  .ksOd,
  .godisteOd,
  .godisteDo,
  .ksDo,
  .brSatiOd,
  .brSatiDo,
  .kwOd,
  .kmOd,
  .kmDo,
  .cm3Od,
  .brVrataOd,
  .brVrataDo,
  .cm3Do,
  .kwDo,
  .cijenaDo,
  .cijenaOd,
  .duzinaOd,
  .sirinaOd,
  .duzinaDo,
  .sirinaDo {
    width: 93% !important;
    outline: none;
  }
  div[class="pretragaFilter pretragaAutmomobiliContainer"] {
    display: grid;
    grid-template-columns: 357.5px 357.5px;
    column-gap: 8px;
  }

  div[class="pretragaFilter pretragaAutmomobiliContainer"] > div {
  }
  .automobiliDodajOglasContainer {
    padding-top: 5rem;
  }
}
@media (max-width: 799px) {
  .kategorijePc {
    display: none;
  }
}

@media (min-width: 799px) {
  .kategorije {
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .podaciPcDodajOglas > .autoFilterContainer {
    display: grid;
    grid-template-columns: 33% 65%;
  }
  .atributi {
    margin: 0rem 0.9rem !important;
  }
  .autoFilterContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5rem;
  }
  .dodatnaOprema,
  .enterijer {
    row-gap: 0.77rem;
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
@media screen and (min-width: 900px) {
  .dodatnaOprema,
  .enterijer {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
}
@media screen and (min-width: 1200px) {
  .h1DivDodajOglas,
  .osnovneInformacije {
    grid-column: span 4;
  }
  .autoFilterContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .atributi {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
