.pretragaPoFilterimaContainer .reklameNoveObjave {
  width: fit-content;
}
.sort {
  cursor: pointer;
}
.pretragaPoFilterimaContainer .pretragaBckg,
.pretragaPoFilterimaContainer .rasporedOglasa {
  display: none;
}
.pretragaPoFilterimaContainer .pretraga1,
.pretragaPoFilterimaContainer .pretraga2 {
  display: none;
}
.pretragaPoFilterimaContainer .noveObjaveHeader {
  justify-content: space-between;
}
.modify {
  background-color: #f2f3f5;
  display: flex;
  gap: 5px;
  font-size: 0.8rem;
  padding: 0.3rem;
  font-weight: 600;
  color: #515151;
  align-items: center;
  border-radius: 10px;
}
.modifyIcons {
  width: 1rem;
}
.sort {
  border: 1px solid #f2f3f5;
  display: flex;
  gap: 5px;
  font-size: 0.75rem;
  padding: 0.5rem;
  font-weight: 600;
  color: #777886;
  align-items: center;
  border-radius: 10px;
}
.headerPretraga {
  display: flex;
  gap: 7px;

  margin: 0.5rem 0.9rem;
}
.sortirajPo {
  left: -43px;
  position: absolute;
  top: 37px;
  width: 10rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px 0px #00000029;
  z-index: 50;
  gap: 10px;
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  border-radius: 10px;
}
.sortirajPo div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #777886;
}
.sortSelect {
  width: 1rem;
}
.uporediIcons {
  width: 1.5rem;
}
.vozilo1 {
  background-color: rgba(0, 0, 0, 0.274);
}
@media screen and (min-width: 777px) {
  .pretragaPoFilterimaContainer .result1280,
  .modify,
  .sort {
    transform: skew(20deg);
  }
  .pretragaPoFilterimaContainer .kocke {
    position: absolute;
  }
  .sortirajPo {
  }
  .pretragaPoFilterimaContainer .divKategorijaPretraga {
    margin: 4rem 0 0 15px;
  }
  .sdfsf {
    display: none;
  }
  .pretragaPoFilterimaContainer .selectedKategorija,
  .pretragaPoFilterimaContainer .selectKategorija {
    width: 2rem;
    height: 2.5rem;
    padding: 0.3rem 1.23rem;
  }
  .pretragaPoFilterimaContainer .tabbar,
  .pretragaFilterr {
  }
  .pretragaPoFilterimaContainer {
    background-color: #ebebeb;

    padding-top: 2rem;
    height: 96.6vh;
  }
  .headerPretraga {
    margin-top: 5rem;
    z-index: 50;
    transform: skew(-20deg);
    align-items: center;
    background-color: white;
    padding: 7px;
    border-right: 20px solid #c35d79;
  }
  .pretragaPoFilterimaContainer .divOglas {
    background-color: white;
  }
  .headerPretragaDiv {
    display: grid;
    grid-template-columns: 723px;
    justify-content: center;
  }
}
@media (min-width: 1013px) {
  .pretragaPoFilterimaContainer .noveObjaveListaOglasi .noveObjaveHeader {
    width: 90%;
  }
  .pretragaPoFilterimaContainer .noveObjaveHeader {
    justify-content: space-between;

    width: 723px;
    margin: auto;
  }
}

@media (max-width: 1280px) {
  .result1280 {
    display: none;
  }
}

@media (min-width: 1280px) {
  .pretragaPoFilterimaContainer .noveObjave {
    grid-template-columns: 1fr;
  }
  .headerPretragaDiv {
    justify-content: left;
  }
  .headerPretraga {
    margin-bottom: 18px;
  }
  .result1280 {
    padding-left: 6px;
    display: block;
    padding-right: 250px;
    color: #282424;
    font-size: 0.9rem;
  }
}
@media (min-width: 1500px) {
  .result1280 {
    padding-right: 472px;
  }
}
