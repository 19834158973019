.pretragaFilterMobile .divKategorijaPretraga {
  display: flex !important;
}
.bottomAuto {
  bottom: auto;
}
.sirinaDiv {
  margin: 0.5rem 0;
}
.gumeP,
.felneSaGumama,
.felneP {
  margin: 0;
}
.gumefelne {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  place-items: center;
  background-color: #f2f3f5;
  border-radius: 14px;
  margin-bottom: 0.5rem;
}
.felneSaGumama {
  place-items: center;
  text-align: -webkit-center;
  display: grid;
  height: 2.5rem;
  width: -webkit-fill-available;
  font-size: 0.9rem;
  font-weight: 600;
  color: #5a5a65;
  border-radius: 14px;
}
.gumeP {
  place-items: center;
  display: grid;
  height: 2.5rem;
  width: -webkit-fill-available;
  font-size: 0.9rem;
  font-weight: 600;
  color: #5a5a65;
  border-radius: 14px;
}
.felneP {
  place-items: center;
  display: grid;
  color: #5a5a65;
  height: 2.5rem;
  width: -webkit-fill-available;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 14px;
}
.selectGumeFelne {
  background-color: #f2f3f5;
}
.selectedGumeFelne {
  background-color: #c35d79;
  color: white;
}
.gumeMobileSearch .godisteDiv {
  margin-bottom: 0.5rem;
}
.pocetnaContainer .pretragaFilter {
  padding-bottom: 75px;
}
.gumeMobileSearch .oznakaInput {
  margin-bottom: 0;
  border: 1px solid #d0d0d07a;
}
.pretragaFilterMobile .duzinaDiv,
.sirinaDiv {
  margin-bottom: 0.5rem;
}
#pretraziContainerMobile {
  margin: 0;
}
.pretragaFilter {
  margin: 0.9rem;
}
.headerMobileSearch {
  display: flex;
  align-items: center;

  flex-direction: row;
  justify-content: space-between;
  margin: 0.9rem;
}
.pretragaFilterMobile .stanjeContainer {
  margin-top: 0.5rem;
}
.pretragaFilterMobile {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  bottom: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
}
.pretragaFilterMobile .kategorijePocetna div .divKategorijaPretraga {
  border-radius: 13px;
  height: 100%;
}
.pretragaFilterMobile .nazivKategorija p {
  display: none;
}
.pretragaFilterMobile
  .kategorijePocetna
  .divKategorijaPretraga
  .LinkBorderRight
  .nazivKategorija
  .selectedKategorija,
.pretragaFilterMobile
  .kategorijePocetna
  .divKategorijaPretraga
  .LinkBorderRight
  .nazivKategorija
  .selectKategorija {
  width: 2.5rem;
  height: 2.2rem;
  padding: 0.3rem 0.85rem;
}
.pretragaFilterMobile .selectedKategorija {
}
.detaljnaBtn {
  background-color: #e8e8e8;
  display: flex;
  height: 2.75rem;
  box-shadow: 0 0 3px 1px rgb(192, 192, 192);
  margin-bottom: 0.5rem;
  border-radius: 1vw;
  align-items: center;
  place-content: center;
  font-size: 0.8rem;
  color: #77787e;
  font-weight: 600;
}
.pretragaFilterMobile .nazivKategorija.selectedColor {
  border-radius: 13px;
}
#buttoniContainerMobile {
  display: grid;
  gap: 0.5rem;
  position: fixed;
  padding-top: 1rem;
  margin: 0.5rem 0;
  grid-template-columns: 1fr 1fr;
  z-index: 999;
  bottom: 0.9rem;
  right: 0.9rem;
  left: 0.9rem;
}
