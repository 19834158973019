.nastavi {
  background-color: #c35d79;
  color: #ffffff;
  border: 2px solid #c35d79;
  width: 100%;
  border-radius: 7px;
  display: flex;

  font-weight: 600;
  font-size: 0.8rem;
  place-items: center;
  place-content: center;
  height: 39px;
}
.nastaviStrelica {
  width: 16px;
  padding-left: 5px;
}
.obaveznoPolje {
  position: absolute;
  color: red;
  top: -9px;
  right: 0px;
  z-index: 2;
  font-size: 1.3rem;
}
.naslovOpisa,
.naslovCijena {
  font-size: 0.9rem;
  color: #5a5a65;
  font-weight: 600;
}
.podDivOpis {
  position: relative;
  display: flex;
  /* padding-bottom: 0.5rem; */
  gap: 1vw;
  width: max-content;
}
.opis {
  position: relative;
  margin: 0rem 0.9rem;
}
.inputDivOpis {
  margin-bottom: 0.5rem;
}
.inputOpis {
  height: 9.563rem;
  width: -webkit-fill-available;
  background-color: #f2f3f5;
  border: none;
  padding-top: 11px;
  font-weight: 600;
  outline: none;
  border-radius: 7px;
  padding-left: 10px;
  color: #5a5a65;
  font-size: 0.9rem;
}

.cijenaProizvoda {
  position: relative;
  margin-top: 0.3rem;
}
.kategorijaDiv {
  position: relative;
}
