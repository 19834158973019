#app {
    height: 100%;
  }
  html,
  body {
   
    
  }
  
  body {

    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
 
    margin: 0;
    padding: 0;
  }
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
  }
  
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
    border-radius: 0px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mySwiper > .swiper-wrapper > div[class="swiper-slide swiper-slide-active"] {
 
  opacity:1;
  }
  .mySwiper {

    padding: 0;
    padding-top: 14px;
}

 .swiper-button-prev, .swiper-button-next {
    display: inline-flex !important;
}
.swiper-button-prev, .swiper-button-next {

    color: #ffffff;
    filter: drop-shadow(0px 0px 7px black);

}

div[class="swiper swiper-initialized swiper-horizontal swiper-watch-progress swiper-backface-hidden mySwiper swiper-thumbs"] > div{
    overflow: auto;
}
div[class="swiper swiper-initialized swiper-horizontal swiper-watch-progress swiper-backface-hidden mySwiper swiper-thumbs"]

.carsImages
{
  cursor: pointer;
    width: 163px;
    height: 105px;
}

.swiper-slide img {

    width: 856px;
    height: 533px;}

    @media screen and (max-width: 577px) {
      .mySwiper {
        height: 297px !important;
      }}
      @media screen and (max-width: 500px) {
        div[class="swiper swiper-initialized swiper-horizontal swiper-backface-hidden mySwiper"] > div[class="swiper-wrapper"] > div {
          width: 100% !important;
          border-radius: 10px;
        }
      }
      @media screen and (min-width: 1200px) and (max-width:2500px){
        .mySwiper .swiper-slide {
          width: max-content !important;}}

    @media screen and (max-width: 777px) {
      
      .mySwiper {
        height: 397px;
      }
      .pcHeaderAds,    div[class="swiper swiper-initialized swiper-horizontal swiper-watch-progress swiper-backface-hidden mySwiper swiper-thumbs"] {
        display: none !important;
      }
    }
@media (min-width: 1200px) {
  div[class="swiper swiper-initialized swiper-horizontal mySwiper swiper-backface-hidden"] >  .swiper-wrapper > .swiper-slide swiper {
    width: 100% !important;
  }
  .carsImages {
    border-radius: 0 !important;
  }
}
/* Stilovi za ekrane širine manje od 1200px */
@media screen and (max-width: 1200px) {
  .containerDodatnaOprema, .opisAdsContainer, .containerUserInfoAds, .nazoviContainer {
    width: auto !important;
  }
  .stickyContainer .swiper {
    display:none}
  div[class="swiper swiper-initialized swiper-horizontal swiper-backface-hidden mySwiper"] > div[class="swiper-wrapper"]  > div{
    width: 100% !important;
    border-radius: 10px;
}
  .swiper-slide img {
    width: 100%;
    height: 100%;
  }
  div.swiper-slide.swiper-slide-active {
 
    border-radius: 10px;
  }
  div[class="swiper-slide swiper-slide-visible swiper-slide-active swiper-slide-thumb-active"] {
    width: max-content;
    border-radius: 10px;
}
div[class="swiper-wrapper"] div[class="swiper-slide swiper-slide-visible swiper-slide-active swiper-slide-thumb-active"] {
  width: max-content ;
  border-radius: 10px;
}


}
