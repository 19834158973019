.oglas .nazoviContainer {
  padding: 0 0.9rem 0rem 0.9rem;
}
.oglas .porukaContainer,
.oglas .nazoviBtn {
  margin-top: 0;
}
.slicniOglasi .pcFilterSide,
.slicniOglasi .reklameNoveObjave {
  display: none;
}
.pcFilterSideDiv > .reklameNoveObjave {
}
.uporediVozila .rasporedOglasa {
  display: none;
}
.pretragaPoFilterimaContainer .bckgImg {
  display: none;
}
.pocetnaContainer {
  overflow-x: hidden;
}
.pocetnaContainer .pcFilterSide {
  display: none;
}
.hover01 figure {
  position: relative;
}
.pcFilterSideDiv #buttoniContainerMobile {
  padding-top: 0;
}
.pcFilterSideDiv .kwP,
.pcFilterSideDiv .pcFilterSideDiv .kpp,
.pcFilterSideDiv .godisteP,
.pcFilterSideDiv .kmP,
.pcFilterSideDiv .cm3P,
.pcFilterSideDiv .brVrataP,
.pcFilterSideDiv .brSatiP,
.pcFilterSideDiv .cijenaDodajOglasP,
.pcFilterSideDiv .cijenaP,
.pcFilterSideDiv .ksP,
.pcFilterSideDiv .duzinaP,
.pcFilterSideDiv .sirinaP {
  width: 51px;
}
.hover01 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.pcFilterSideDiv .godiste,
.pcFilterSideDiv .km {
  margin-bottom: 0.5rem;
}
.pcFilterSideDiv .nastavi {
  width: 100%;
}
.pcFilterSideDiv .butoniPretraga {
  position: relative !important;
  grid-template-columns: 1fr !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
.pcFilterDetaljnaPretraga {
  font-weight: 600;
  padding: 0.9rem 0.9rem 0 0.9rem;
  font-size: 14px;
  color: #a5a5a5;
}
.pcFilterSide {
  background-color: white;
  border-radius: 4px;
  height: fit-content;

  margin: 0 0.9rem;
}
.pcFilterSideDiv .pretragaFilter {
  grid-template-columns: 1fr !important;
}
.pcFilterSideDiv .bigMenu,
.pcFilterSideDiv .headerMobileSearch,
.pcFilterSideDiv .stilovi,
.pcFilterSideDiv .headPhone,
.pcFilterSideDiv .divKategorijaPretraga {
  display: none;
}
.hover01 figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.okvirOglasSlike {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin: 0 0 0 25px;
  padding: 0;
}
.sectionHoverImg {
  display: flex;
  gap: 2px;
  padding-bottom: 7px;
  align-items: center;
}
.sectionHoverImg p {
  font-size: 12px;

  color: white;
  font-weight: 600;
}
.okvirOglasSlike:first-child {
  margin-left: 0;
}
.column div div {
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: -1;
  display: flex;
  gap: 99px;
  padding: 0 10px;
  justify-content: space-between;
  width: -webkit-fill-available;
  margin: 0;

  color: #444;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  opacity: 0;
}
.averStarHover {
  font-size: 12px;
}
.averStarHover .averStar {
  width: 12px;
}

.averStarHover {
  margin-bottom: 7px;
  display: flex;
  font-size: 12px;
  color: white;
  align-items: center;
  font-weight: 700;
  gap: 2px;
  background-color: orange;
  padding: 2px;
  border-radius: 7px;
}
.brSlikaHover {
  width: 16px;
  height: 16px;
}

figure {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

figure:hover + div {
  background: linear-gradient(to bottom, transparent, #00000087);
  bottom: 0;
  padding: 0 10px;
  justify-content: space-between;
  width: -webkit-fill-available;
  display: flex;
  opacity: 1;
  z-index: 8;
}

/* Zoom In #1 */
.hover01 figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.hover01 figure:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.kockaPodaci {
  display: flex;
  gap: 2px;

  align-items: center;
}
.noveObjave {
  position: relative;
}
.divAutoOglasnikNaslov {
}
.autoOglasnik {
  font-weight: 600;
  color: #c35d79;
  font-size: 17px;
  padding: 1rem;
  display: grid;
  place-items: center;
}
.noveObjaveContainerAds {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.reklameImg {
  width: 100%;
  height: 167px;
  cursor: pointer;
  border-radius: 5px;
}
.slikeNovihOglasaLista {
  object-fit: cover;
}
.noveObjaveHeader {
  align-items: center;
  height: 4rem;
  justify-content: space-between;
  display: flex;
  position: relative;
}
.noveObjaveH1 {
  color: #282424;
  font-size: 0.9rem;
}
.pocetnaContainer {
  background-color: white;
}
.kockaPodaciP {
  font-size: 2.44vw;
  color: #777886;
  font-weight: 500;
}
.kockaPodaciIcons {
  width: 1rem;
  height: 1rem;
  padding-right: 2px;
}
.noveObjave {
  margin: 0 0.9rem;
  padding-bottom: 1rem;
}
.slikeNovihOglasa {
  width: 100%;
  height: 8.5rem;
  border-radius: 5px;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.placeForAds {
  position: relative;
  margin-top: 13px;
  z-index: 0;
  text-align: center;
  font-size: 13px;
  color: #707070;
  width: -webkit-fill-available;
  height: 118px;
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-weight: bold;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#c35d79, #ffffff),
      linear-gradient(#c35d79, #ffffff), linear-gradient(#c35d79, #ffffff),
      linear-gradient(#c35d79, #ffffff);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }
}

.noveObjaveOglasi {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3vw;
}
.naslovOglasa {
  font-size: 0.8rem;
  color: #282424;
  font-weight: 600;
}
.cijenaOglasa {
  color: #c35d79;
  font-size: 0.75rem;
  font-weight: 700;
}
.divOglas {
  height: fit-content;
  text-align: left;
  padding: 0.4rem;
  border: 1px solid gainsboro;
  border-radius: 5px;
}
.vrijemeLokacija {
  padding: 0.5rem 0px;
  display: grid;
  gap: 7px;
  justify-content: space-between;
  grid-template-columns: 1.1fr 1fr;
}
.divVrijeme,
.divLokacija {
  display: flex;
  align-items: center;
}
.prodavacP {
  color: #c35d79;
  font-size: 1rem;

  font-weight: 500;
}
.vrijemeOglasa,
.lokacijaOglasa,
.mjenjacOglasP,
.listaPodaciP,
.gorivoOglasP {
  font-size: 2.44vw;
  color: #777886;
  font-weight: 500;
}
.lokacijaIcons {
  width: 1rem;
  height: 1rem;
  padding-right: 2px;
}
.vrijemeIcons {
  padding-right: 2px;
  width: 1rem;
  height: 1rem;
}
.divSlikeNoviOglas {
  position: relative;
}
.srce {
  position: absolute;
  bottom: 4vw;
  right: 3vw;
  width: 1rem;
  height: 1rem;
  background-color: #00000059;
  padding: 1.5vw;
  border-radius: 6vw;
}

.viewBlockCon {
  background-color: white;
  cursor: pointer;
  display: none;
  position: absolute;
  right: 16px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  top: 43px;
  z-index: 55;
  flex-direction: column;
  gap: 16px;
  padding: 10px 29px 10px 5px;
  width: 4rem;
  border-radius: 10px;
}
.viewBlockDivKocke {
  display: flex;
  align-items: center;
}
.kocke {
  cursor: pointer;
}
.viewBlockDivLista {
  display: flex;
  align-items: center;
}
.viewBlockIcons {
  width: 1.5rem;

  height: 1.5rem;
}
.viewBlockP {
  color: #777886;
  font-size: 0.9rem;
}
.flex {
  display: flex;
}
.noveObjaveLista {
  grid-template-columns: 1fr;
}
.slikeNovihOglasaLista {
  object-fit: cover;
}
.naslovOglasaLista {
  font-size: 16px;
  font-weight: 700;
  top: 0.7rem;
}
.cijenaOglasaLista {
  font-size: 12px;
}
.mjenjacOglas,
.gorivoOglas {
  display: none;
}
.mjenjacOglasLista,
.gorivoOglasLista {
  display: flex;
  align-items: center;
}
.mjenjacIcons,
.listaPodaciIcons,
.gorivoIcons {
  width: 1rem;
  height: 1rem;
  padding-right: 2px;
}
.liked {
  display: none;
}
.active {
  position: absolute;
  bottom: 4vw;
  right: 3vw;
  width: 1rem;
  height: 1rem;
  display: block;
  padding: 1.5vw;
  border-radius: 6vw;
}
.opisOglasa {
  margin: 0 0.9rem;
}
.divOglasLista .divSlikeNoviOglas {
  display: grid;
  grid-template-columns: 42% 56%;
  gap: 2%;
}
.naslovOglasaAds {
  font-weight: 700;
  font-size: 1rem;
}
.divOglasLista {
  height: 7.5rem;
}
.noveObjaveLista .listaPodaci {
  display: flex;
  align-items: center;
}
.noveObjaveLista .vrijemeLokacija {
}
.noveObjaveLista .mjenjacIcons,
.noveObjaveLista .listaPodaciIcons,
.noveObjaveLista .gorivoIcons {
  width: 12px;
  height: 12px;
  padding-right: 2px;
}
.sponzorisano {
  color: white;
  font-weight: 600;
  place-content: center;
  align-items: center;
  left: -35px;
  top: 8px;
  font-size: 1.1rem;
  display: flex;
  z-index: 8;
  width: 108px;
  height: 25px;
  background-color: #c35d79;
  position: absolute;
  transform: rotate(-47deg);
  /* transform-origin: 50% 50%; */
  /* margin: 50px; */
  clip-path: polygon(
    0% 100%,
    53% -126%,
    100% 94%,
    16% 100%,
    10% 100%,
    107% 94%,
    14% 90%,
    90% 90%
  );
}
.donjiTrougao {
  left: -8.5px;
  top: 50.6px;
  width: 0;
  height: 0;
  border-left: 8.7px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 18px solid #a8546a;
  position: absolute;
}
.gornjiTrougao {
  left: 45.8px;
  top: -11px;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: -2px solid transparent;
  border-bottom: 11.5px solid #a8546a;
  position: absolute;
}
.zIndex-1 {
  z-index: -1;
  position: relative;
}

.zatamniVozilo1 {
  background-color: rgba(0, 0, 0, 0.185) !important;
}
.buttoniUporedi {
}
.buttoniUporedi a {
  background-color: #c35d79;
  text-align: center;

  display: flex;
  height: 3rem;
  font-size: 0.8rem;
  box-shadow: 0px 4px 12px 0px #00000029;
  position: fixed;
  right: 0.9rem;
  left: 0.9rem;
  bottom: 70px;
  z-index: 84;
  font-weight: 600;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 2vw;
}
@media screen and (min-width: 400px) {
  .noveObjaveLista .mjenjacIcons,
  .noveObjaveLista .listaPodaciIcons,
  .noveObjaveLista .gorivoIcons {
    width: 16px;
    height: 16px;
  }
  .divOglasLista {
    height: 10.5rem;
  }
  .noveObjaveLista .slikeNovihOglasa {
    height: 10.5rem;
  }
  .cijenaOglasaLista {
    font-size: 15px;
  }
  .noveObjaveLista .vrijemeOglasa,
  .noveObjaveLista .lokacijaOglasa,
  .noveObjaveLista .mjenjacOglasP,
  .noveObjaveLista .listaPodaciP,
  .noveObjaveLista .gorivoOglasP {
    font-size: 12.5px;
    color: #777886;
    font-weight: 500;
  }
  .noveObjaveListaOglasi .noveObjaveContainerAds {
    display: block;
  }
}
@media only screen and (min-width: 320px) and (max-width: 400px) {
  .figure,
  .okvirOglasSlike,
  .slikeNovihOglasa {
    width: 100%;
  }
  .slikeNovihOglasa {
    height: 7.5rem;
  }
  .naslovOglasa {
    font-size: 0.7rem;
  }
  .kockaPodaciP,
  .lokacijaOglasa,
  .vrijemeOglasa {
    font-size: 2.4vw;
    font-weight: 600;
  }
  .pocetnaContainer .vrijemeIcons {
    width: 12px;
    height: 12px;
  }
}
@media screen and (max-width: 411px) {
  .pocetnaContainer .kockaPodaciIcons,
  .pocetnaContainer .lokacijaIcons,
  .pocetnaContainer .vrijemeIcons {
    width: 0.8rem;
    height: 0.8rem;
  }
  .vrijemeLokacija {
    grid-template-columns: 56% 44%;
    gap: 4px;
  }
}

@media screen and (min-width: 611px) {
  .noveObjaveListaOglasi .noveObjaveOglasi {
    display: grid;
    grid-template-columns: 1fr;
  }
  .noveObjaveOglasi {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .noveObjaveListaOglasi .listaPodaciIcons,
  .noveObjaveListaOglasi .listaPodaci,
  .noveObjaveListaOglasi .rasporedOglasa {
    display: flex;
  }
  .noveObjaveListaOglasi .listaPodaciP {
    display: block;
  }
}

@media screen and (max-width: 777px) {
  .noveObjaveListaOglasi .rasporedOglasa {
    display: none;
  }
  .listaPodaciProdavac {
    display: none;
  }
  .rasporedOglasa {
    display: none;
  }
}
@media screen and (max-width: 777px) and (min-width: 611px) {
  .kockaPodaciP {
    font-size: 0.62rem;
    font-weight: 500;
  }
  .cijenaOglasa {
    font-size: 1rem;
  }

  .listaPodaciP {
    display: none;
  }
  .listaPodaciIcons,
  .listaPodaci,
  .rasporedOglasa {
    display: none;
  }
}

@media screen and (min-width: 777px) {
  .pocetnaContainer .viewBlockCon {
    justify-self: right;
    background-color: white;
    cursor: pointer;

    position: relative;
    right: 16px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
    top: 8px;
    z-index: 55;
    flex-direction: column;
    gap: 16px;
    padding: 10px 29px 10px 5px;
    width: 4rem;
    border-radius: 10px;
  }
  .buttoniUporedi {
    position: relative;
  }
  .buttoniUporedi a {
    background-color: #c35d79;
    text-align: center;
    display: flex;
    height: 2.5rem;
    font-size: 0.8rem;
    box-shadow: 0px 4px 12px 0px #00000029;
    position: absolute;
    right: 0;
    left: auto;
    top: 13px;
    z-index: 84;
    font-weight: 600;
    color: white;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    padding: 0 10px;
  }
  .pretragaFilterr .bigMenu {
    display: none;
  }
  .pretragaFilterMobile {
    top: 77px !important;
  }
  .pretragaPoFilterimaContainer .stanjeContainer,
  .pretragaPoFilterimaContainer .tabliceContainer {
    margin: 0;
  }
  .pretragaPoFilterimaContainer .pretragaFilter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
  }
  .pretragaPoFilterimaContainer .ksDiv {
    margin-top: 0;
  }
  .pretragaPoFilterimaContainer .ks,
  .pretragaPoFilterimaContainer .cm3,
  .pretragaPoFilterimaContainer .brSati,
  .pretragaPoFilterimaContainer .kw {
    margin-bottom: 0.5rem;
  }
  .pretragaPoFilterimaContainer .search {
    display: none;
  }
  .noveObjaveLista .samoListe {
    width: -webkit-fill-available;
  }
  .noveObjaveLista .listaPodaciProdavac {
    grid-column: span 2;
    align-items: center;
  }
  .noveObjaveLista .prosjecnaOcjena {
    position: relative;
  }
  .noveObjaveLista .divNaslovCijena {
    display: flex;
    justify-content: space-between;
  }
  .noveOvjaveLista .divLokacija .noveObjaveLista .vrijemeOglasa,
  .noveObjaveLista .lokacijaOglasa,
  .noveObjaveLista .mjenjacOglasP,
  .noveObjaveLista .listaPodaciP,
  .noveObjaveLista .gorivoOglasP {
    font-size: 15.5px;
  }
  .samoListe .vrijemeLokacija div {
    display: flex;
  }
  .noveObjaveLista .mjenjacIcons,
  .noveObjaveLista .listaPodaciIcons,
  .noveObjaveLista .gorivoIcons {
    width: 20px;
    height: 20px;
  }
  .noveObjaveLista .slikeNovihOglasa {
    height: 13.5rem;
  }
  .noveObjaveListaOglasi .okvirOglasSlike {
    width: auto;
  }
  .divOglasLista {
    height: 13.5rem;
  }
  .noveObjaveListaOglasi .okvirOglasSlike {
    width: auto;
  }
  .cijenaOglasa {
    font-size: 1rem;
  }
  .noveObjaveH1 {
    display: none;
  }
}

@media screen and (min-width: 500px) {
  .noveObjaveKocke {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .vrijemeOglasa,
  .lokacijaOglasa,
  .mjenjacOglasP,
  .gorivoOglasP {
    font-size: 0.7rem;
    color: #777886;
  }
}
@media screen and (min-width: 732px) {
  .likedPost .noveObjaveHeader {
    width: 593px;
    justify-content: space-between;
    margin: auto;
  }
}

@media screen and (min-width: 777px) {
  .likedPost .noveObjaveHeader {
    width: 713px;
  }
  .lokacijaOglasa {
    font-size: 0.85rem;
  }
  .pocetnaContainer .noveObjaveH1 {
    display: none;
  }
  .noveObjaveHeader {
    justify-content: end;
  }

  .naslovOglasa {
    font-size: 1rem;
  }
  .slikeNovihOglasa {
    width: 100%;
    height: 10.5rem;
    border-radius: 5px;
  }
  .noveObjaveHeader {
    grid-template-columns: 693px;
    width: 100%;
    display: grid;
    justify-content: center;
  }
  .kocke {
    cursor: pointer;

    display: flex;
    justify-self: right;
  }
  .cijenaOglasaLista {
    color: #ffb900;

    font-size: 1.2rem;
  }

  .mjenjacIcons,
  .listaPodaciIcons,
  .gorivoIcons {
    width: 1.2rem;
    height: 1.2rem;
    padding-right: 2px;
  }
  .noveObjaveOglasi {
  }
  .samoListe .vrijemeLokacija .divVrijeme .vrijemeOglasa,
  .samoListe .vrijemeLokacija .divVrijeme .divLokacija .lokacijaOglasa,
  .listaPodaciP,
  .mjenjacOglasP,
  .samoListe .vrijemeLokacija .divLokacija .lokacijaOglasa,
  .gorivoOglasP {
    font-size: 0.9rem;
    color: #777886;
  }
  .slikeNovihOglasaLista {
    height: 17rem;
    width: 16rem;
  }
  .noveObjaveLista {
    display: grid;
    grid-template-columns: 1fr !important;
    gap: 0.9rem;
  }

  .listaPodaci:last-child {
    grid-column-end: span 2;
  }
  .divOglasLista .divSlikeNoviOglas {
    border-radius: 0px;
    display: flex;
    position: relative;
    height: 13.5rem;
    border-top: 3px solid #c35d79;
    border-bottom: 3px solid #c35d79;
    padding: 0.02rem 0;
  }
  .samoListe {
    padding: 20px 0 0 0px;
  }
  .samoListe .vrijemeLokacija {
    display: grid;
    flex-direction: column;
    gap: 14px;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
  }
  .samoListe .vrijemeLokacija div {
    gap: 4px !important;
  }
  .listaPodaci {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #777886;
  }
  .listaPodaci .prosjecnaOcjena {
    position: relative;
  }
  .samoListe .vrijemeLokacija .divVrijeme {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  .lokacijaIcons {
    width: 1.2rem;
    height: 1.2rem;
  }
  .rasporedOglasa {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    gap: 7px;
    background-color: #f2f3f5;
    border-radius: 47px;
    margin-bottom: 0.5rem;
    width: 25rem;
    height: 3rem;
    margin: auto;
    margin-top: 3rem;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
  }
  .selectedAutomobili,
  .selectedSviOglasi,
  .selectedZamjena {
    background-color: #c35d79;
    color: white !important;
    border-radius: 50px;
    /* margin: 0.5rem 0; */
  }
  .sviOglasiP:hover {
    background-color: #c35d79;
    color: white !important;
  }
  .automobiliP:hover {
    background-color: #c35d79;
    color: white !important;
  }
  .zamjenaPRaspored:hover {
    background-color: #c35d79;
    color: white !important;
  }
  .sviOglasiP,
  .automobiliP,
  .zamjenaPRaspored {
    place-items: center;

    margin: 0;
    display: grid;
    color: #5a5a65;
    transition: background-color 0.9s ease;
    height: 2.5rem;
    width: -webkit-fill-available;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 47px;
  }
}

@media only screen and (max-width: 610px) and (min-width: 455px) {
  .vrijemeOglasa,
  .lokacijaOglasa {
    font-size: 0.75rem;
  }
  .kockaPodaciP {
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 610px) and (max-width: 776px) {
  .kockaPodaciP {
    font-size: 0.69rem;
  }
  .vrijemeOglasa,
  .lokacijaOglasa {
    font-size: 0.69rem;
  }
}
@media only screen and (min-width: 777px) and (max-width: 1014px) {
  .noveObjave {
    display: grid;
    grid-template-columns: 723px;
    justify-content: center;
  }
  .kockaPodaciP {
    font-size: 0.95rem;
  }
  .lokacijaOglasa,
  .vrijemeOglasa {
    font-size: 0.9rem;
  }
  .cijenaOglasa {
    font-size: 0.95rem;
  }
}
@media screen and (min-width: 611px) {
  .buttoniUporedi {
    position: relative;
    width: 726px;
    margin: auto;
  }
}
@media only screen and (min-width: 972px) {
  .noveObjaveListaOglasi .noveObjaveHeader {
    width: 100%;
    justify-content: end;
  }
  .noveObjaveListaOglasi .rasporedOglasa {
  }
  .noveObjaveListaOglasi {
  }
}
@media only screen and (min-width: 1015px) {
  .noveObjaveListaOglasi .noveObjaveHeader {
    width: 90%;
    justify-content: end;
  }
  .noveObjaveListaOglasi .pcFilterSideDiv {
    place-content: center;
    display: grid;
    grid-template-columns: 80%;
    gap: 1%;
  }

  .noveObjaveListaOglasi .reklameNoveObjave {
    width: auto;
  }
  .noveObjaveOglasi {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 1015px) {
  .hover01 .animationHover {
    display: none;
  }
}
@media only screen and (min-width: 1015px) {
  .rasporedOglasa {
  }
  .kockaPodaciP,
  .vrijemeOglasa,
  .lokacijaOglasa {
    font-size: 14px;
  }
  .noveObjaveOglasi {
    grid-template-columns: 231px 231px 231px;
    justify-content: left;
  }
  .reklameNoveObjave {
    background-color: white;
    height: fit-content;
    padding: 15px;
    margin-left: 7px;
    box-shadow: 0 0 4px 4px #ededed;
    border-radius: 7px;
    position: relative;
    width: 242px;
  }
}

@media screen and (max-width: 1014px) {
  .reklameNoveObjave {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .slicniOglasi .pcFilterSideDiv {
    display: grid;
    gap: 1%;
    grid-template-columns: 1fr;
  }
  .slicniOglasi .noveObjaveOglasi {
    grid-template-columns: 231px 231px 231px 231px;
    justify-content: left;
  }
}
@media only screen and (max-width: 1279px) {
  .pcFilterSide,
  .reklameNoveObjave {
    display: none;
  }
}
@media only screen and (min-width: 1280px) {
  .noveObjaveHeader {
    height: 3rem;
  }
  .pocetnaContainer .noveObjaveListaOglasi .noveObjaveHeader {
    width: 922px;
  }
  .pocetnaContainer .noveObjaveListaOglasi .pcFilterSideDiv {
    place-content: center;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1%;
  }
  .noveObjaveListaOglasi .pcFilterSideDiv {
    place-content: center;
    display: grid;
    grid-template-columns: 1.2fr 3fr 1fr;
    gap: 1%;
  }
  .noveObjaveContainerAds {
    gap: 10px;
  }
  .pocetnaContainer .noveObjaveOglasi {
    grid-template-columns: 231px 231px 231px 231px;
    justify-content: left;
  }
  .buttoniUporedi {
    width: 58%;
  }
  .buttoniUporedi a {
    background-color: #c35d79;
    text-align: center;
    display: flex;
    height: 2.5rem;
    font-size: 0.8rem;
    box-shadow: 0px 4px 12px 0px #00000029;
    position: absolute;
    right: 22px;
    left: auto;
    top: -62px;
    z-index: 84;
    font-weight: 600;
    color: white;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    padding: 0 10px;
  }
  .kocke {
    right: 0;
    top: 2px;
    z-index: 55;
  }
  .pretragaPoFilterimaContainer .noveObjaveHeader {
    position: absolute;

    top: -57px;
    left: 0;
    display: grid;
    grid-template-columns: 1247px !important;
  }
  .pretragaPoFilterimaContainer .noveObjaveH1 {
    display: none;
  }
  div.modify {
    display: none !important;
  }
  .headerPretragaDiv {
    grid-template-columns: 80% !important;
    margin-left: 25px;
  }
  .pretragaPoFilterimaContainer .noveObjaveHeader {
    width: 75% !important;

    padding-left: 21px;
  }
  .pcFilterSideDiv > .pcFilterSide > .pretragaFilterr > .hide {
    display: block;
  }
  .pretragaPoFilterimaContainer {
    height: auto !important;
  }
  .pcFilterSideDiv {
    display: grid;

    gap: 1%;
    grid-template-columns: 0.9fr 2fr 0.8fr;
  }
  .likedPost .noveObjaveHeader {
    width: 941px;
  }
  .rasporedOglasa {
    margin-left: 24%;
  }
  .pocetnaContainer .noveObjaveHeader {
  }
  .pretragaPoFilterimaContainer .noveObjaveOglasi {
    grid-template-columns: repeat(3, 217px);
    height: fit-content;
  }
  .noveObjave {
    display: grid;
    grid-template-columns: 1238px;
    justify-content: center;
  }
}
@media only screen and (min-width: 1300px) {
  .slicniOglasi .noveObjaveOglasi {
    grid-template-columns: 231px 231px 231px 231px 231px;
    justify-content: left;
  }
}
@media only screen and (min-width: 1450px) {
  .buttoniUporedi {
    width: 64%;
  }
  .pretragaPoFilterimaContainer .noveObjaveOglasi {
    grid-template-columns: repeat(4, 215px);
  }
  .headerPretragaDiv {
    grid-template-columns: 83% !important;
  }
  .pretragaPoFilterimaContainer .noveObjaveHeader {
    width: 78% !important;
  }
  .result1280 {
    padding-right: 30% !important;
  }
}
@media only screen and (min-width: 1500px) {
  .noveObjaveListaOglasi .pcFilterSideDiv {
    place-content: center;
    display: grid;
    grid-template-columns: 1.2fr 4fr 1fr;
    gap: 1%;
  }
  .noveObjaveHeader {
    grid-template-columns: 59%;
  }
  .pocetnaContainer .noveObjaveOglasi {
    grid-template-columns: 231px 231px 231px 231px 231px;
    justify-content: left;
  }
  .headerPretragaDiv {
  }
  .pretragaPoFilterimaContainer {
    overflow-x: auto;
  }
  .pocetnaContainer .pcFilterSideDiv {
    display: grid;
    gap: 16px;
  }
  .likedPost .noveObjaveHeader {
    width: 1190px;
  }
  .noveObjaveContainerAds {
    gap: 11px;
  }
  .rasporedOglasa {
    margin-left: 28%;
  }
  .pocetnaContainer .noveObjaveListaOglasi .noveObjaveHeader {
    width: 1115px;
  }
  .noveObjave {
    grid-template-columns: 1495px;
  }
}

@media only screen and (min-width: 1650px) {
  .pcFilterSide {
    max-width: 300px;
  }
  .reklameNoveObjave {
    max-width: 270px;
  }
  .result1280 {
    padding-right: 36% !important;
  }
  .pretragaPoFilterimaContainer .headerPretragaDiv {
    grid-template-columns: 86% !important;
  }
  .buttoniUporedi {
    width: 70%;
  }
  .pretragaPoFilterimaContainer .noveObjaveHeader {
    width: 82% !important;
  }
  .pretragaPoFilterimaContainer .noveObjaveOglasi {
    grid-template-columns: repeat(5, 215px);
  }
}
