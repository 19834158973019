.butoniPretraga {
  margin: 0 0.9rem;
}
.stilovi {
  background-color: #ffffff;
  color: #c35d79;
  border: 2px solid #c35d79;
  width: 100%;
  border-radius: 7px;
  display: grid;
  font-weight: 600;
  font-size: 0.8rem;
  place-items: center;
}
@media screen and (min-width: 800px) {
  .butoniPretraga {
    display: grid;
    grid-template-columns: 800px;
    justify-content: center;
    margin-top: 0.9rem;
  }
}
@media screen and (min-width: 1200px) {
  .butoniPretraga {
    grid-template-columns: 1200px;
  }
}
