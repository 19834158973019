.headerAktivni{
  display: flex;
  justify-content: space-between;   
   align-items: center;
}
.aktivni{
  padding: .9rem;
}
.naslovAktivniOglasi{
  font-size: .9rem;
  font-weight: 600;
}
.divAktivniOglasi{
  font-size: .9rem;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  font-weight: 600;
}
.aktivni .hr{
  
  margin-top: 1rem;
}
.imgMojiOglasi{
  width: 14rem;   
  border-radius: 14px;
  margin-top: 0.5rem;
}
.aktivniOglas{
  flex-direction: column;
    display: flex;
    gap: 1vw;
    font-size: .9rem;
    font-weight: 600;
    color: rgb(48, 48, 48);
}
.atributiDiv{
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-self: center;
}
.markaModelDatum{
  padding-top: .3rem;
  display: flex;
  justify-content: space-between;
  
}
.cijenaMojiOglasi{
  
    color: white;
    background-color: #c35d79;
    width: fit-content;
    padding: 0.3rem 1rem;
    border-left: inset;
border-color: black;
}
.aktivniOglas .flex{
  gap: 1.5vw;
}
.mojiOglasiOpcije{
  display: flex;

  margin-top: .5rem;
  justify-content: space-between;
  background-color: rgb(241, 241, 241);
  padding: 5px;
  border-radius: 2vw 

}
.promovisiIcons{
  width: 1.2rem;
  height: 1.2rem;
}
.mojiOglasiOpcije div{gap: 1px;
  display: flex;
  align-items: center;
  color: rgb(48, 48, 48);

}
.headerAktivni .logo{
  height: 3rem;
  width: 7rem;
  margin: 0;
  padding: 0;
}
.mojiOglasiOpcije div p{
  font-size: .9rem;
color: #5A5A65;
}@media screen and (min-width: 777px) {
  .aktivni{
    padding-top: 7rem;
  }
}
@media screen and (min-width: 800px) {
  .mojiOglasiOpcije {

    justify-content: space-around;
  }
  .aktivniOglas{
    font-size: 1.1rem;
  }.naslovAktivniOglasi {
    font-size: 1.3rem;
}
  .aktivni {
    padding-top: 9rem;
    display: grid;
    grid-template-columns: 750px;
    justify-content: center;
  }
  .headerAktivni .logo {
    height: 5rem;
    width: 10rem;}

}
@media screen and (min-width: 1200px) {
  .aktivni {

    grid-template-columns: 1100px;
}}