* {
  font-family: sans-serif !important;
}
.headerAds {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
  border-bottom: 1px solid #dbdbdb;
}
.preko1200Hide .swiper-watch-progress {
  display: none;
}
.headerAds > .headerIconsPc > div {
  padding-left: 7px;
  height: 1.5rem;
  transform: skew(-20deg);
  gap: 7px;
  border-left: 5px solid #c35d79;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.posaljiPonudu {
  margin-top: 0.9rem;
  background-color: #c35d79;
  width: -webkit-fill-available;
  display: flex;
  text-align: center;
  height: 3.5rem;
  cursor: pointer;
  align-items: center;
  margin: 0.9rem 0.9rem 0 0.9rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  color: white;
  gap: 7px;
  justify-content: center;
  border-radius: 7px;
}
.ponudaDiv .posaljiPonudu {
  margin: 0.9rem 0 0.9rem 0 !important;
}
.posaljiPonudu:hover {
  color: #ffffff;
  background-color: #ffb900;
  box-shadow: 1px 3px 10px 0px #8e8e8e;
}
.dropInfoNaslov {
  font-size: 0.8rem;
  color: #5a5a65;
  font-weight: 600;
}
.dropInfo {
  align-self: center;
}
.oglasSacuvanp::after {
  content: "";
  position: absolute;
  top: -9px;
  /* left: 50%; */
  transform: rotate(180deg);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 12px solid white;
}

.show {
  display: block;
}
.errorPonuda {
  display: none;
  color: red;
  font-size: 0.7rem;
  font-weight: 500;
  padding-left: 2px;
  padding-top: 1px;
}
.oglasSacuvanp {
  left: 14px;
  position: absolute;
  top: 30px;
  transform: skew(20deg);
  font-size: 0.8rem;
  background-color: white;
  /* height: 1.2rem; */
  padding: 6px;
  display: grid;
  place-items: center;
  border-radius: 3px;
}
.ponudaEuro {
  position: absolute;
  height: 2.5rem;
  top: 0;
  right: 0;
  width: 51px;
  display: grid;
  place-items: center;
  color: white;
  border-top-right-radius: 7px;
  /* border-radius: 7px; */
  background-color: #c35d79;
  border-bottom-right-radius: 7px;
}
.ponudaDivInput {
  position: relative;
  width: 100%;
}
.ponudaInput {
  width: -webkit-fill-available;
  height: 2.5rem;
  padding-left: 7px;
  font-weight: 500;
  border-radius: 7px;
  border: none;
}
.ponudaDiv {
  display: grid;
  place-items: center;
  position: relative;

  margin: 0.9rem;
  color: #5a5a65;
  height: 7rem;
  font-weight: 600;
}
.showDropInfo {
  display: none;
}

.hideDropInfo {
  display: flex;
}
.headerAds {
  position: fixed;
  z-index: 7;
  top: 0;
  left: 0;
  width: -webkit-fill-available;
  height: 3rem;
  background-color: #fff;
}
.headerIcons {
  display: flex;
  gap: 1rem;
  cursor: pointer;
}
.shareIcons,
.reportIcons {
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
}
.hrr {
  border-bottom: 2px solid #e3e5ed;
  margin: 0.9rem;
}
.nazadPcVer {
  width: 2rem;
  height: 2rem;
}
.oglas {
  background-color: #ebebeb;
}
a:active {
  color: inherit;
}
a {
  color: inherit;
}
.nazadOglas {
  display: flex;
  align-items: center;
}
.headerIconsPc > div > img {
  transform: skew(20deg);
}
.headerIconsPc > div {
  padding-left: 7px;
  height: 1.2rem;
  transform: skew(-20deg);
  border-left: 5px solid #c35d79;
}
.carsImages {
  width: 100%;
  height: 17rem;
  object-fit: cover;
  border-radius: 10px;
}
.divOglasSlika {
  padding: 0 0.9rem 0.9rem 0.9rem;
  position: relative;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}
.indexSlike {
  font-weight: 600;
  font-size: 0.8rem;
  background-color: #28242494;
  color: white;
  position: absolute;
  bottom: 1rem;
  z-index: 1;
  width: 2rem;
  height: 1.5rem;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  right: 1rem;
}
.ssrce {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: #00000059;
  padding: 1.5vw;
  border-radius: 6vw;
  z-index: 5;
}

.container1Ads {
  background-color: white;
  position: relative;
  padding-bottom: 1.2rem;
  border-bottom-right-radius: 7vw;
  border-bottom-left-radius: 7vw;
}
.cijenaOglasaAds {
  font-size: 0.9rem;
  padding-top: 0.2rem;
  font-weight: 600;
  color: #c35d79;
}
.naslovOglasaAds {
  color: #404040;
}
.mjenjacAds,
.snagaAds,
.kmAds,
.gorivoAds,
.godinaAds,
.lokacijaAds {
  padding-top: 1rem;
  display: flex;
  align-items: center;
}
.mjenjacAdsP,
.snagaAdsP,
.kmAdsP,
.godinaAdsP,
.gorivoAdsP,
.lokacijaAdsP {
  font-size: 0.9rem;
  color: #777886;
  font-weight: 600;
}
.karakteristikeOglasa {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
.snagaIcons {
  width: 1.2rem;
  height: 1rem;
  padding-right: 1vw;
}
.container2Ads {
  border-radius: 2vw;
  background-color: white;
  margin: 0.9rem;
  padding: 0.9rem;
}
.osnovneInformacijeAds {
  font-size: 1rem;
  font-weight: 600;
  color: #282424;
}
.container2Ads .hr {
  padding-top: 1rem;
}
.markaModelNaslov,
.karoserijaNaslov,
.pogonNaslov,
.tabliceNaslov,
.brVrataNaslov,
.cm3Naslov,
.regNaslov,
.zamjenaNaslov,
.dodatnaOpremaAdsNaslov,
.ostecenjeNaslov,
.bojaNaslov,
.unutrasnjaOpremaAdsNaslov,
.brBrzinaNaslov,
.oznakaNaslov {
  color: #777886 !important;
  font-size: 0.9rem;
  font-weight: 500 !important;
}
.markaModelAds,
.cm3Ads,
.tabliceAds,
.regAds,
.zamjenaAds,
.ostecenjeAds,
.bojaAds,
.brVrataAds,
.brBrzinaAds,
.pogonAds,
.karoserijaAds,
.oznakaAds {
  display: flex;
  padding-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}
.markaModelP,
.pogonP,
.tabliceP,
.ostecenjeP,
.bojaP,
.brVrataP,
.dodatnaOpremaP,
.brBrzinaP,
.zamjenaP,
.regP,
.cm3PAds,
.karoserijaP,
.oznakaP {
  font-size: 0.8rem;
  font-weight: 600;
  color: #5a5a65;
}
.dodatneInformacijeAdsNaslov {
  font-size: 1rem;
  font-weight: 600;
  color: #282424;
}
.containerDodatnaOprema {
  border-radius: 2vw;
  background-color: white;
  margin: 0.9rem;
  padding: 0.9rem;
}
.containerDodatnaOprema .hr {
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.dodatnaOpremaAds {
  display: flex;
  flex-direction: column;
  gap: 3vw;
}
.unutrasnjaOpremaAdsNaslov {
  padding-top: 1.5rem;
}
.hrUnutrasnjaOprema {
  border-bottom: 1px solid #e3e5ed;
}
.containerUserInfoAds {
  border-radius: 2vw;
  background-color: white;
  margin: 0.9rem;
  padding: 0.9rem;
}
.slikaImeAds {
  display: flex;
  position: relative;
  align-items: center;
  gap: 2vw;
}
.prodavac {
  color: #282424;
  font-size: 0.8rem;
  font-weight: 500;
}
.hrLokacija {
  border-bottom: 1px solid #e3e5ed;
  margin-bottom: 1rem;
  padding-top: 1rem;
}
.lokacijaAdsDiv {
  display: flex;
  align-items: center;
}
.lokacijaPAds {
  color: #c35d79;
  font-size: 0.8rem;
  font-weight: 600;
}
.brTelefonaNaslov {
  color: #777886;
  font-size: 0.9rem;
  font-weight: 500;
}
.brTelefonaP {
  color: #c35d79;
  font-size: 1rem;
  font-weight: 600;
  padding-top: 0.3rem;
}
.nazoviBtn {
  margin-top: 1rem;
  background-color: #c35d79;
  width: 100%;
  display: flex;
  text-align: center;
  height: 3.5rem;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  gap: 7px;
  justify-content: center;
  border-radius: 7px;
}
.callIcon {
  width: 1.5rem;
  height: 1.5rem;
}
.nazoviContainer {
  grid-template-columns: 1fr 1fr;
  display: grid;
  gap: 2vw;
  border-radius: 2vw;
  padding: 0 0.9rem 2rem 0.9rem;
}
.opisAdsContainer {
  border-radius: 2vw;
  background-color: white;
  margin: 0.9rem;
  padding: 0.9rem;
}
.opisNaslovAds {
  font-size: 1rem;
  font-weight: 600;
  color: #282424;
}
.hrOpis {
  margin: 0.7rem 0;
  border-bottom: 1px solid #e3e5ed;
}
.opisTekst {
  font-size: 0.8rem;
  font-weight: 600;
  color: #5a5a65;
  line-height: 1rem;
}
.correct {
  width: 1rem;
  padding-left: 1vw;
  display: inline;
}
.porukaContainer {
  border: 1px solid #5a5a65;
  margin-top: 1rem;
  background-color: white;
  width: 100%;
  display: flex;
  text-align: center;
  height: 3.5rem;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #5a5a65;
  gap: 7px;
  justify-content: center;
  border-radius: 7px;
}
.porukaIcons {
  width: 1rem;
}
.openImage {
  display: none;
}
.displayBlock {
  height: 89vh;
  overflow: hidden;
  display: block;
  /* height: 100%; */
  position: relative;
  z-index: 88;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  padding-top: 45%;
}
.divOglasSlikaa {
  /* padding: 0.5rem; */
  overflow: hidden;
  position: relative;
}
.carsImagess {
  width: 100%;
  height: 17rem;
}
.displayHide {
  display: none;
}

.closeBtn {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  width: 1.5rem;
}
.brVrataPOglas {
  font-size: 0.8rem;
  font-weight: 600;
  color: #5a5a65;
}
.prosjecnaOcjena {
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background-color: #ffb900;
  width: min-content;
  padding: 0.2rem;
  gap: 1px;
  border-radius: 2vw;
  color: white;
}
.averStar {
  width: 20px;
}
.prosjecnaOcjenaP {
  font-size: 0.9rem;
  font-weight: 600;
}
.razlogPrijave {
  width: -webkit-fill-available;
  height: 7rem;
  border: none;
  background-color: #f2f3f5;
  border-radius: 20px;
  padding-right: 3rem;
  padding-left: 7px;
  padding-top: 19px;
  font-size: 0.8rem;
  padding-bottom: 2rem;
  font-weight: 500;
}
.btnPrijavi {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 0.9rem;
  width: -webkit-fill-available;
  /* position: absolute; */
  bottom: 1px;
  height: 3rem;
  color: white;
  border: none;
  background-color: #c35d79;
  border-radius: 15px;
  right: 1px;
}
.prijaviDiv {
  top: 50%;
  left: 0;
  right: 0;
  padding: 0.9rem;
  bottom: 0;
  z-index: 4848;
  /* top: 17px; */
  /* right: 33px; */
  background-color: white;
  border-top-left-radius: 51px;
  border-top-right-radius: 51px;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.stickyContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.pcVerision {
  top: 0.9rem;
  position: sticky;
}
.ponudaPoslata.ponudaPoslata {
  position: absolute;
  font-size: 1rem;
  top: 0px;
  left: 0;
  right: 0;
  display: flex;
  place-self: center;
  background-color: #ebebeb;
  width: -webkit-fill-available;
  place-content: center;
  height: 3rem;
  align-items: center;
}
.ostaliOglasi {
  text-decoration: underline;
  color: #c35d79;
}
.ostaliOglasi:active {
  color: #c35d79;
}
.prijaviteOglasNaslov {
  align-self: flex-start;
  margin: 2rem 0 1rem 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.divReporta {
  z-index: 2343;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 8;
  background-color: #00000069;
  filter: brightness(0.5);
}

.showTranzicija {
  display: block;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.headerIconsPc {
  display: flex;
  gap: 25px;
}
.pcHeaderAds {
  color: #404040;
  align-items: center;
  margin: 0 0.9rem;
  display: flex;
  border-bottom: 2px solid #c5c5c5;
  padding-top: 2rem;
  justify-content: space-between;
}
.pcHeaderAds > div > div {
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  font-weight: 500;
  align-items: center;
  gap: 6px;
}
.prijaviDiv,
.divReporta {
  animation: slideInFromBottom 0.7s ease-in-out forwards;
}

.animated-border {
  z-index: 1;
  border-radius: 2vw;
  border: 2px solid transparent;
  animation: animateBorder 2s linear infinite;
}
.divOglasSlikaa .carsImages {
  border-radius: 0;
}
.divOglasSlikaa .swiper-watch-progress {
  display: none;
}
@keyframes animateBorder {
  0% {
    box-shadow: 0 0 0 2px #777886; /* Početni efekat crte oko diva */
  }
  50% {
    box-shadow: 0 0 0 4px #777886; /* Najšira tačka animacije */
  }
  100% {
    box-shadow: 0 0 0 2px #777886; /* Krajnji efekat crte oko diva */
  }
}
@media (max-width: 380px) {
  .mjenjacAdsP,
  .snagaAdsP,
  .kmAdsP,
  .godinaAdsP,
  .gorivoAdsP,
  .lokacijaAdsP {
    font-size: 0.8rem;
  }
}

@media (min-width: 500px) {
  .mjenjacIcons,
  .listaPodaciIcons,
  .gorivoIcons,
  .snagaIcons,
  .lokacijaIcons {
    width: 2rem;
    height: 2rem;
  }

  .mjenjacAdsP,
  .snagaAdsP,
  .kmAdsP,
  .godinaAdsP,
  .gorivoAdsP,
  .lokacijaAdsP {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 2000px) {
  .containerDodatnaOprema,
  .opisAdsContainer,
  .containerUserInfoAds,
  .nazoviContainer {
    width: 826px;
  }
}
@media screen and (max-width: 500px) {
  .backAdsBtn {
    width: 1.7rem;
  }
  .markaModelAds,
  .cm3Ads,
  .tabliceAds,
  .regAds,
  .zamjenaAds,
  .ostecenjeAds,
  .bojaAds,
  .brVrataAds,
  .brBrzinaAds,
  .pogonAds,
  .karoserijaAds,
  .oznakaAds {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    flex-direction: column;
    gap: 5px;
  }
  :root {
    --swiper-navigation-size: 30px;
  }
  .naslovRecenzije {
    font-size: 15px;
  }
  .komentar {
    font-size: 0.7rem;
  }

  .ratingStars {
    padding: 0;
  }
  .recenzijaBtn {
    font-size: 0.85rem;
  }
  .recenzijaImg {
    width: 4rem;
    height: 4rem;
  }

  .recenzijaDivVrijeme {
    display: flex;
    padding-top: 4px;
    position: relative;
    padding-bottom: 7px;
  }
  .recenzijaIme {
    font-size: 14px;
  }
  .recenzijeKupaca > div {
    gap: 6px;
  }
  .recenzijaKomentar {
    font-size: 13px;
  }
  .dodatnaOpremaPAds {
    grid-template-columns: 1fr !important;
  }
}

@media screen and (min-width: 500px) {
  .carsImagess {
    width: 100%;
    height: 22rem;
  }

  .displayBlock {
    position: absolute;
    height: 89vh;
    overflow: hidden;
    display: block;
    z-index: 88;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    padding-top: 45%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media screen and (max-width: 650px) {
  .dodatnaOpremaPAds {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .displayBlock {
    padding-top: 37%;
  }
  .headerAds > .headerIconsPc > div {
    border: none;
  }
  .oglasSacuvanp {
    left: -18px;
    width: max-content;
    top: 34px;
  }
  .headerIconsPc {
    gap: 7px;
  }
  .headerAds > .headerIconsPc > div > p:first-child {
    display: none;
  }
}

@media screen and (min-width: 650px) {
  .carsImagess {
    height: 24rem;
  }
}
@media screen and (max-width: 776px) {
  .mySwiper .swiper-slide {
    opacity: 1;
  }
  .oglasSacuvanp {
    background-color: #ebebeb;

    color: #535353 !important;
  }
  .oglasSacuvanp::after {
    border-top: 12px solid #ebebeb;
  }

  .container1Ads {
    padding-top: 4rem;
  }
}
@media screen and (min-width: 777px) {
  .animated-border {
    border-radius: 15px;
  }
  .headerAds {
    display: none;
  }
  .displayBlock,
  .openImage {
    display: none;
  }
  .mjenjacIcons,
  .listaPodaciIcons,
  .gorivoIcons,
  .snagaIcons,
  .lokacijaIcons {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mjenjacAdsP,
  .snagaAdsP,
  .kmAdsP,
  .godinaAdsP,
  .gorivoAdsP,
  .lokacijaAdsP {
    font-size: 1rem;
  }
  .container1Ads {
    top: 1rem;
  }
  .markaModelAds,
  .cm3Ads,
  .tabliceAds,
  .regAds,
  .zamjenaAds,
  .ostecenjeAds,
  .bojaAds,
  .brVrataAds,
  .brBrzinaAds,
  .pogonAds,
  .karoserijaAds,
  .oznakaAds {
    display: flex;
    height: 51px;
    align-items: center;
    /* padding-top: 1rem; */
    flex-direction: row;
    justify-content: space-between;
    /* padding-bottom: 12px; */
    border-bottom: 1px solid gainsboro;
  }
  .headerAds {
    position: fixed;
    z-index: 7;
    top: 72px;
    left: 0;
    width: -webkit-fill-available;
    height: 4rem;
    background-color: #fff;
  }
  .container1Ads {
  }
  .carsImages {
    max-height: 553px;
    height: 23rem;
    max-width: 856px;
  }
  .oglas {
    padding-top: 5.5rem;
  }
  .karakteristikeOglasa {
    gap: 10px;
    justify-items: center;
    grid-template-columns: 0.5fr 0.5fr 0.8fr 0.8fr 0.6fr 0.8fr;
  }
  .container1Ads {
    background-color: transparent;
  }
  .opisOglasa {
    background-color: white;
    margin: 0 0.9rem;
    padding: 20px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .markaModelCijenaDiv {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 800px) {
  .displayBlock {
    padding-top: 25%;
  }
  .carsImagess {
    height: 27rem;
  }
  .naslovOglasaAds,
  .cijenaOglasaAds {
    font-size: 1.2rem;
  }

  .oglas {
    display: grid;
    justify-content: center;
    grid-template-columns: 800px;
  }
}
@media screen and (min-width: 1000px) {
  .carsImagess {
    height: 33rem;
  }
  .displayBlock {
    padding-top: 20%;
  }
  .container1Ads {
    padding-top: 0rem;
  }

  .headerAds {
    top: 0;
  }
  .oglas {
    padding-top: 6rem;
  }
}
@media screen and (max-width: 1200px) and (min-width: 650px) {
  .dodatnaOpremaPAds {
    display: grid;
    gap: 4px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1200px) {
  .pcVerision {
    display: none;
  }
  .sticky {
    display: none;
  }
  .stickyContainer {
    grid-template-columns: 1fr;
  }
  .dodatnaOpremaAds {
    gap: 7px;
  }
}
@media screen and (min-width: 1300px) {
  .oglas {
    grid-template-columns: 1285px !important;
  }
}

@media screen and (min-width: 1200px) {
  .markaModelAds,
  .cm3Ads,
  .tabliceAds,
  .regAds,
  .zamjenaAds,
  .ostecenjeAds,
  .bojaAds,
  .brVrataAds,
  .brBrzinaAds,
  .pogonAds,
  .karoserijaAds,
  .oznakaAds {
    padding: 0;
  }
  .preko1200Hide {
    display: none;
  }
  .lokacijaAdsDiv > .lokacijaIcons {
    padding-right: 3px;
    width: 1.5rem;
    height: 1.5rem;
  }

  .container1Ads {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  .divOglasSlika {
    order: 0 !important;
    place-self: flex-start;
  }

  .container1Ads > div:nth-child(2) {
    grid-row: 1;
  }
  .opisOglasa {
    grid-column: 1 / span 2;
  }

  .container1Ads > div:nth-child(1),
  .container1Ads > div:nth-child(3) {
    grid-row: 2;
  }
  .pcVerision {
    border-radius: 10px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 675px;
  }
  .markaModelAds,
  .cm3Ads,
  .tabliceAds,
  .regAds,
  .zamjenaAds,
  .ostecenjeAds,
  .bojaAds,
  .brVrataAds,
  .brBrzinaAds,
  .pogonAds,
  .karoserijaAds,
  .oznakaAds {
  }

  .container1Ads > div {
  }

  .nonPcVerision {
    display: none;
  }

  .carsImages {
    height: 553px;
  }
  .oglas {
    grid-template-columns: 1150px;
  }
  .container1Ads {
    display: flex;
    flex-direction: column;
  }
  .divOglasSlika {
    order: 2;
    width: 856px;
  }
  .karakteristikeOglasa {
    grid-template-columns: 0.7fr 0.7fr 1fr 1fr 1fr 1fr;
    height: auto;
  }
  .mjenjacIcons,
  .listaPodaciIcons,
  .gorivoIcons,
  .snagaIcons,
  .snagaIcons,
  .lokacijaIcons {
    padding-right: 3px;
    width: 2rem;
    height: 2rem;
  }
  .mjenjacAdsP,
  .snagaAdsP,
  .kmAdsP,
  .godinaAdsP,
  .gorivoAdsP,
  .lokacijaAdsP {
    font-size: 1.4rem;
  }

  .mjenjacAds,
  .snagaAds,
  .kmAds,
  .gorivoAds,
  .godinaAds,
  .lokacijaAds {
    padding-left: 10px;
    padding-right: 7px;
    border-left: 11px solid #c35d79;
    background-color: white;
    height: 3rem;
    transform: skew(-20deg);
    margin-top: 1rem;
    display: flex;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding-top: 0;
    place-items: center;
  }
  .container1Ads > .container2Ads {
    margin: 0.9rem 0.9rem 0.9rem 0rem;
  }
  .naslovOglasaAds,
  .cijenaOglasaAds {
    font-size: 1.5rem;
  }
  .markaModelNaslov,
  .karoserijaNaslov,
  .pogonNaslov,
  .tabliceNaslov,
  .brVrataNaslov,
  .cm3Naslov,
  .regNaslov,
  .zamjenaNaslov,
  .dodatnaOpremaAdsNaslov,
  .ostecenjeNaslov,
  .bojaNaslov,
  .unutrasnjaOpremaAdsNaslov,
  .brBrzinaNaslov,
  .oznakaNaslov {
    color: #777886 !important;
    font-size: 1.1rem;
    margin: 0;
    font-weight: 500 !important;
  }
  .markaModelP,
  .pogonP,
  .tabliceP,
  .ostecenjeP,
  .bojaP,
  .brVrataPOglas,
  .brVrataP,
  .dodatnaOpremaP,
  .brBrzinaP,
  .zamjenaP,
  .regP,
  .cm3PAds,
  .karoserijaP,
  .oznakaP {
    font-size: 1rem;
    margin: 0;
  }
  .opisOglasa {
    padding: 0;
    background-color: transparent;
  }
  .containerDodatnaOprema {
    border-radius: 10px;
  }
  .dodatnaOpremaPAds {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 17px;
  }
  .dodatnaOpremaAds {
    gap: 20px;
  }
  .opisAdsContainer {
    border-radius: 10px;
  }
  .containerUserInfoAds {
    border-radius: 10px;
  }
  .lokacijaPAds,
  .brTelefonaNaslov,
  .brTelefonaP {
    font-size: 1.1rem;
  }
  .imeUser {
    padding-bottom: 7px;
  }
  .prodavac {
    font-size: 0.9rem;
  }
  .ratingStars .svg {
    width: 30px;
  }
}
