.istorijaPlacanja{
  margin: .9rem;
}
.istorijaPlacanjaDiv {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 2vw;
  padding: 1rem 0.5rem;
  background-color: #F2F3F5;
  border-radius: 10px;
  font-size: .85rem;

  font-weight: 600;
  color: #282424;
}
.opisPlacenogOglasa{
  display: flex;
  gap: 7px;
  flex-direction: column;
}
.opisPlacenogOglasa p:last-child{
  color: #c35d79;
}
@media screen and (min-width: 800px) {
  .istorijaPlacanja{

    display: grid;
    grid-template-columns: 770px;
    justify-content: center;

}}
@media screen and (min-width: 777px) {
  
  .istorijaPlacanja{
    padding-top: 7rem;
  }
}