.kategorijePocetna div .divKategorijaPretraga{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  margin:1rem .9rem 0rem .9rem ;
  padding: 0;
  
}
.nazivKategorija{
  display: grid;
  place-content: center;
  height: 100%;
place-items: center;
  font-size: .65rem;
  font-weight: 600;
  width: -webkit-fill-available;
color: #2E2E2E;
}
.nazivKategorija p{
  text-align: center;

}
.selectedColor p{
  color: #ffffff;
}
.selectedDiv{
  background-color: #c35d79;
  
}
.pocetnaContainer .hr{
margin: 0rem .9rem 0 .9rem;
}
.sveKategorije{
  margin: 1rem .9rem 0 .9rem;
  font-size: 0.9rem;
}
.selectedColor{
  background-color: #c35d79;
}
@media screen and (max-width: 777px)  {
  .kategorijePocetna div .divKategorijaPretraga {

  }}

@media screen and (min-width: 777px)  {
  .pretragaFilterPc .pretragaBckg, .pretragaFilterPc .bckgImg{
    display:none}
    .pretragaFilterPc {
      display: none;
     }
}