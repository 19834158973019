.mojiOglasiNaslov {
  font-size: 1rem;
  font-weight: 600;
  color: #282424;padding-bottom: 0.5rem;
}
.mojiOglasi {
  margin: 0 0.9rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.statusOglasa {
  color: #777886;
  font-size: 0.9rem;
  font-weight: 600;
}
.mojiOglasiLinkovi {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brojOglasa {
  background-color: #c35d79;
  color: white;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 20vw;
  font-size: 0.8rem;
  align-items: center;
  display: flex;font-weight: 600;
  place-content: center;
}
