.nacinPlacanja {
}
.karticeChoose {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.7rem;
  gap: 1vw;
  box-shadow: 0px 4px 12px 0px #00000029;
}
.karticeChoose div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.razmak {
  padding: 0 0.5rem;
  font-size: 1.2rem;
}
.nacinPlacanja .headerAktivni {
  margin-bottom: 2rem;
}
.platiKarticom:active {
  color: white;
}
.platiKarticom {
  background-color: #ff5f00f0;
  width: -webkit-fill-available;
  border: none;
  color: white;

  place-content: center;
  display: grid;
  height: 2.5rem;
  border-radius: 7px;
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 0.9rem;
}
.uplatnice div div {
  display: flex;
  padding-top: 0.5rem;
  justify-content: space-between;
}
.uplatnice {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px 0px #00000029;

  background-color: white;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.7rem;
  gap: 1vw;
}
.slikajUplatnicu {
  text-align: center;
  background-color: #a5a5a5;
  /* padding: 0.5rem; */
  border-radius: 7px;
  margin-top: 0.5rem;
  color: white;
  height: 2.5rem;
  align-items: center;
  display: grid;
}
.uplatniceNaslov {
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
}
.imgUplatnica {
  margin-top: 2rem;
  width: -webkit-fill-available;
  height: 10rem;
}
.posaljiUplatnicu {
  border: none;
  width: -webkit-fill-available;
  text-align: center;
  background-color: #c35d79;
  /* padding: 0.5rem; */
  border-radius: 7px;
  margin-top: 0.5rem;
  color: white;
  height: 2.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  align-items: center;
  display: grid;
}
.successMessage {
  display: grid;
  place-content: center;
  position: absolute;
  background-color: #f2f3f5;
  height: -webkit-fill-available;
  top: 0;
  left: 0;
  right: 0;
  font-size: 1rem;
  z-index: 1;
  font-weight: 500;
}
.successMessage p {
  font-size: 1.5rem;
  font-weight: 600;
  color: #171718;
}
.successMessage div {
  display: grid;
  place-items: center;
  position: relative;
  place-content: center;
}
.vector {
  position: absolute;
  z-index: 1;
  left: 100px;
  top: 28px;
}
.allDone {
  z-index: 2;
  background-color: transparent;
}

.nacinPlacanja {
  padding: 0.9rem;
}
.sacuvanaKartica {
  display: flex;
  justify-content: space-between;
  background-color: white;
  height: 4.5rem;
  cursor: pointer;
  border-radius: 7px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.sacuvanaKartica div {
  display: flex;
  align-items: center;
  gap: 7px;
}
.dodajNacinPlacanja {
  height: 100vh;
  background-color: #f2f3f5;
}
.dodajNoviDiv {
  display: flex;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.dodajNoviDiv div {
  width: 100%;
  border: 1px solid #cecece;
  height: 0px;
  display: flex;
  align-self: center;
}
.pDodajNovi {
  color: #b4b4b4;
  font-size: 0.8rem;
  width: 12rem;
  font-weight: 600;
  margin: 0 1rem;
}
@media screen and (min-width: 777px) {
  .nacinPlacanja .headerAktivni {
    padding-top: 6rem;
  }
  .dodajNacinPlacanja {
    padding-top: 7rem;
  }
}
@media screen and (min-width: 800px) {
  .nacinSacuvaneKartice {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 19px;
  }

  .sacuvanaKartica,
  .karticeChoose {
    font-size: 1rem;
  }

  .platiKarticom {
    height: 2.7rem;
    font-size: 1rem;
  }

  .sacuvanaKartica div img,
  .karticeChoose div div img {
    width: 3rem;
    height: 3rem;
  }

  .nacinPlacanja {
    display: grid;
    grid-template-columns: 800px;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .imgUplatnica {
    margin-top: 2rem;
    width: -webkit-fill-available;
    height: 21rem;
    object-fit: cover;
  }

  .karticeChoose {
    width: -webkit-fill-available;
  }
  .uplatnice {
    margin-top: 0;
    width: -webkit-fill-available;
  }
  .karticeUplatnice {
    display: flex;

    gap: 19px;
  }
  .nacinPlacanja {
    grid-template-columns: 1200px;
  }
}
