.statistickiPodaci > div > p:last-child {
  color: #c35d79;
  font-weight: 600;
}
.statistikaHeader {
  margin: 0.9rem;
  display: flex;
  justify-content: space-between;
}
.statistikaHeader .logoDodajOglas {
  margin: 0;
}
.statistikaNaslov {
  font-size: 0.9rem;
  align-self: center;
  font-weight: 600;
}
.borderLeft {
  height: 100%;
  width: 1px;
  margin-left: 10.5%;
  border-left: 1.8px solid #abacb6;
}
.jazPregleda {
  justify-content: space-between;
  height: 16rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  /* gap: 1.54vh; */
  color: #abacb6;
  font-weight: 500;
  font-size: 0.8rem;
}
.grafikon {
  margin: 0.9rem;
  height: 16rem;
}
.borderBottomGrafik {
  width: 89.5%;
  height: 1px;
  margin-left: 10.5%;
  border-color: #abacb6;
  border-bottom: 1.8px solid #abacb6;
}
.limit {
  width: 89.5%;
  height: 1px;

  border-bottom: 1px dashed;
  border-color: #abacb6;
}
.limiti {
  width: 100%;
  width: 100;
  justify-content: space-between;
  height: 16rem;
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 2px;
  position: absolute;
  margin-left: 10.5%;
}
.dani {
  width: 83.5%;
  height: 17rem;
  align-items: self-end;
  margin-left: 10.5%;
  position: absolute;
  bottom: 0;
  padding-left: 3%;
  font-size: 0.8rem;
  font-weight: 500;
  color: #abacb6;
  padding-right: 3%;
  display: flex;
  justify-content: space-between;
}
.pon {
  background-color: #c35d79;
  height: 1rem;
}
.uto {
  height: 3rem;
  background-color: #c35d79;
}
.pon,
.uto,
.sri,
.čet,
.pet,
.sub,
.ned {
  display: flex;
  place-items: end;
  justify-content: center;

  border-top-right-radius: 2vw;
  position: relative;
  z-index: 5;
}

.sri {
  height: 5rem;
  background-color: #c35d79;
}
.čet {
  height: 4rem;
  background-color: #c35d79;
}
.pet {
  height: 7rem;
  background-color: #c35d79;
}
.sub {
  height: 10rem;
  background-color: #c35d79;
}
.ned {
  height: 13rem;
  background-color: #c35d79;
}
.dani div {
  width: 2rem;
}
.dani div span {
  position: absolute;
  bottom: -25px;
}
.statistika .hr {
  margin: 3rem 0.9rem 1.5rem 0.9rem;
}
.statistickiPodaci {
  display: flex;
  flex-direction: column;
  margin: 2rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #abacb6;
  gap: 4vw;
}
.statistickiPodaci div {
  display: flex;
  justify-content: space-between;
}
.statistikaOglasaImg {
  border-radius: 15px;
  width: 5rem;
  height: 5rem;
}
.pojedinacnaStatistika {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0.9rem;
  background-color: #f2f3f5;
}
.pojedinacnaStatistikaDiv {
  background-color: white;
  display: flex;
  gap: 2vw;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
}
.statistikaOglasaNaslov {
  font-size: 1rem;
  color: #282424;
  font-weight: 600;
  padding: 0.9rem 0;
}
.naslovPojedinacne {
  font-size: 0.9rem;
  font-weight: 600;
  color: #282424;
}
.minDivStatsOglas {
  display: flex;
  font-size: 0.75rem;
}
.akcijePojedinacno {
  padding-left: 0.4rem;
  border-left: 1px solid #5a5a65;
  margin: 0 0.4rem;
  color: #5a5a65;
  font-weight: 600;
}
.preglediPojedinacno {
  color: #5a5a65;
  font-weight: 600;
}
.sponzorisanoPojedinacno {
  padding-left: 0.4rem;
  border-left: 1px solid #5a5a65;
  color: #c35d79;
  font-weight: 600;
}
.divOglasPodaciPojedinacni {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 3vw;
}
.statistika {
  overflow-x: hidden;
}
.personalImg {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.personalnaStatistika {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  margin: 2rem 0.9rem;
}
@media screen and (min-width: 777px) {
  .statistika .logoDodajOglas {
    display: none;
  }
  .statistika {
    padding-top: 7rem;
  }
}
@media screen and (min-width: 800px) {
  .statistikaHeader .backk {
    left: 0.9rem;
    position: absolute;
    top: 89px;
  }
  .statistika {
    display: grid;
    grid-template-columns: 770px;
    justify-content: center;
  }
  .statistickiPodaci {
    font-size: 1.1rem;
  }
  .jazPregleda,
  .dani {
    font-size: 1rem;
  }
  .statistikaOglasaNaslov {
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }
  .statistikaOglasaNaslov,
  .personalnaStatistika,
  .statistikaNaslov {
    font-size: 1.3rem;
  }
  .personalImg {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
  }
  .statistikaOglasaImg {
    border-radius: 15px;
    width: 12rem;
    height: 12rem;
  }
  .minDivStatsOglas {
    font-size: 1rem;
    flex-direction: column;
    gap: 7px;
  }
  .akcijePojedinacno,
  .sponzorisanoPojedinacno,
  .preglediPojedinacno {
    padding-left: 0;
    border-left: none;
    margin: 0;
  }
  .naslovPojedinacne {
    font-size: 1.2rem;
  }
  .divOglasPodaciPojedinacni {
    gap: 1rem;
  }
  .statistikaHeader .logoDodajOglas {
    height: 5rem;
    width: 10rem;
  }
}
@media screen and (min-width: 1200px) {
  .statistickiPodaci {
    background-color: #f2f3f5;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 23px;
    margin: 20px 0;
    border-radius: 20px;
    font-size: 1.1rem;
  }
  .statistika {
    display: grid;
    grid-template-columns: 1036px;
    justify-content: center;
  }
  .pojedinacnaStatistika {
    gap: 13px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .statistikaOglasaNaslov {
    grid-column: span 3;
  }
  .statistikaOglasaImg {
    border-radius: 15px;
    width: 8rem;
    height: 8rem;
  }
}
