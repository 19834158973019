.tabbar {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  display: grid;
  background-color: #ffffff;
  place-content: center;
  margin: 0 !important;
  /* display: flex; */
  text-align: center;
  z-index: 999;
  justify-content: space-around;
  padding: 1.5vw 0vw;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}
.tabbarP {
  margin: 0;
  font-size: 0.65rem;
  color: #808080;
}

.tabbar.hidden {
  transform: translateY(100%);
}

.ikonicaTabbar {
  width: 1.5rem;
  height: 1.5rem;
}
a {
  text-decoration: none;
}
@media screen and (min-width: 777px) {
 .pocetnaContainer .tabbar{
    display: none;
  }
}

@media screen and (min-width: 777px) {
 .openMenu .tabbar {
  left: 0;
  background-color: #ffffff;
  top: -3px;
  padding-top: 5rem;
  width: 20rem;
  gap: 4rem;
  justify-content: start;
  display: flex;

  flex-direction: column;
}
.openMenu .tabbar > div:first-child {
  border-top: 1px solid #e7e7e7
}
 .openMenu .tabbar div div a {
    padding-top: 1rem;
    padding-left: 0.9rem;

    position: absolute;
    display: flex;
    gap: 14px;
    align-items: center;
    padding-bottom: 1.3rem;

    width: -webkit-fill-available;
    border-bottom: 1px solid #e7e7e7

}
 .openMenu .tabbarP {
    margin: 0;
    font-size: 0.9rem;
    color: #626262;
    font-weight: 500;
}
.openMenu .tabbar div{
  width: -webkit-fill-available;


}
}
