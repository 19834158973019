.dodajOglas .cijenaDodajOglasInput {
  padding-left: 10px;
}
.kmInput,
.cm3Input,
.kwInput,
.hpInput {
}
.uploadPhoto {
  position: relative;
  border-radius: 2vw;
  margin: 0.9rem;
  text-align-last: center;
}
.dodajFotografijeP {
  align-self: center;
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
}
.divUpload {
  height: 12rem;
  justify-content: center;
  background-color: #c35d79;
  gap: 0.5rem;
  border-radius: 1.5vw;
}
.uploadIcons {
  width: 1rem;
}
.uploadovaneSlike {
  border-radius: 1vw;
  width: 91%;
  object-fit: cover;
  overflow-x: hidden;
  height: 9rem;
  cursor: pointer;
}
.uploadLabel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 1vw;
  align-items: center;
  cursor: pointer;
}
.uploadedPhoto {
  background-color: #f2f3f5;
  border-radius: 4px;
  top: 0vw;
  flex-direction: column;
  display: none;
  gap: 2vw;
  width: 100%;
  left: 0;
  right: 0;
}
.activeUpload {
  display: flex;
  z-index: 8;
}
.divUploadovanihSlika {
  display: grid;
  gap: 3vw;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  overflow: hidden;
  grid-template-columns: 1fr;
}
.naslovSlikeH2 {
  text-align-last: left;
  font-size: 0.9rem;
  padding-top: 1rem;
  padding-left: 1rem;
  font-weight: 600;
  color: #282424;
}
.objasnjenjePDodajOglas {
  font-size: 0.7rem;
  text-align-last: left;
  color: #6e6e6e;
  padding-bottom: 1rem;
}
.objasnjenjeDivUpload {
  margin: 0.9rem 0;
}
.redosledImg {
  display: flex;
  gap: 2px;
}
.brojRedosleda {
  font-size: 0.8rem;
  font-weight: 600;
  color: #282424;
  display: flex;
}
@media screen and (min-width: 500px) {
  .uploadovaneSlike {
    width: 95%;
  }
  .divUploadovanihSlika {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 777px) {
  .uploadedPhoto {
    height: auto;
  }
  .divUploadovanihSlika {
    display: grid;
    row-gap: 27px;
    padding-left: 1rem;
    height: auto;
    overflow: hidden;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .uploadovaneSlike {
    border-radius: 1vw;
    width: 12rem;
    object-fit: cover;
    height: 12rem;
    cursor: pointer;
  }
}
@media screen and (min-width: 800px) {
  .objasnjenjePDodajOglas {
    font-size: 0.9rem;
    text-align-last: left;
    color: #6e6e6e;
    padding-bottom: 1rem;
  }
  .ispod800 {
    display: none;
  }
  .uploadovaneSlike {
    border-radius: 1vw;
  }
  .uploadedPhoto {
  }
}
@media screen and (max-width: 800px) {
  .preko800 {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .divUploadovanihSlika {
    display: grid;
    row-gap: 27px;
    padding-left: 1rem;
    height: auto;
    overflow: hidden;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .objasnjenjePDodajOglas {
    padding-top: 0rem;
  }
}
