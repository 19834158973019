.roundLanguage {
  object-fit: cover;
  border-radius: 100px;
  width: 2rem;
  height: 2rem;
}
.roundLanguageDiv {
  position: absolute;
  z-index: 999;
  top: 47%;
  left: 17px;
  display: flex;
  gap: 17px;
}
.language {
  font-size: 0.8rem;
  align-items: center;
  font-weight: 600;
}
.likedPost .noveObjave .rasporedOglasa {
  display: none;
}
.likedPost .noveObjave {
}
.bigMenu2 {
  color: white;
  height: 100%;
  gap: 60px;
  display: flex;
  z-index: 88;
  align-items: center;
  position: relative;
  place-self: center;
}
.bigMenu {
  right: 0;
  display: grid;
  height: 85px;
  border-bottom: 1px solid #686868;
  align-items: center;
  z-index: 900;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0 20vh;
  justify-content: space-between;
  grid-template-columns: 1fr 7fr 2fr;
}
.bijeliLogo {
  width: 10rem;
  z-index: 88;
  position: relative;
}
.zamraceniMenu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #474747;
}
.pocetnaContainer .zamraceniMenu {
  background-color: #00000054;
}
.searchMenu {
  justify-self: right;
  width: 2rem;
  z-index: 88;
  display: flex;
}
.bigMenuIcons {
  width: 1.5rem;
}
.profilLinkMenu {
}
.bigMenu2 > a {
  display: flex;
  align-items: center;
  gap: 7px;
  transition: color 0.5s ease;
  font-size: 0.8rem;
  font-weight: 600;
}

.bigMenu3 {
  justify-content: space-between;
  z-index: 88;
  display: flex;
  position: relative;
  align-items: center;
  transition: color 0.5s ease;
}
.bigMenu2 > a:hover,
.bigMenu3 a:hover {
  color: #c35d79;
}
.bigMenu3 a:nth-child(1):hover img {
  content: url("https://autoklik.me/icons/menuSearch.png");
}
.bigMenu2 > a:nth-child(1):hover img {
  content: url("https://autoklik.me/icons/bigMenuHomee.png");
}
.bigMenu2 > a:nth-child(2):hover img {
  content: url("https://autoklik.me/icons/bigMenuLikee.png");
}
.bigMenu2 > a:nth-child(3):hover img {
  content: url("https://autoklik.me/icons/bigMenuAddd.png");
}
.bigMenu2 > a:nth-child(4):hover img {
  content: url("https://autoklik.me/icons/bigMenuPorukee.png");
}
.bigMenu2 > a:nth-child(5):hover img {
  content: url("https://autoklik.me/icons/bigMenuProfill.png");
}
.bigMenuIcons:hover,
.searchMenu:hover {
  transition: transform 0.5s ease;
}
.bigMenu2 > a img,
.bigMenu3 a img {
  transition: content 0.5s ease;
}
.bigMenu2 > a {
  position: relative; /* Postavljamo relativni položaj za roditeljski <a> element */
  display: flex; /* Postavljamo kao blok element */
}

.bigMenu2 > a img,
.bigMenu3 a img {
  transition: opacity 0.5s ease; /* Dodajemo tranziciju prozirnosti */
}

.bigMenu2 > a:hover img,
.bigMenu3 a:hover img {
  opacity: 0; /* Postavljamo prozirnost na 0 kada je link na hover */
}
.bigMenu3 a:nth-child(1):hover {
  background-image: url("https://autoklik.me/icons/menuSearch.png");
}
.bigMenu2 > a:nth-child(1):hover {
  height: 24px;
  background-image: url("https://autoklik.me/icons/bigMenuHomee.png");
}
.bigMenu2 > a:nth-child(2):hover {
  height: 24px;
  background-image: url("https://autoklik.me/icons/bigMenuLikee.png");
}
.bigMenu2 > a:nth-child(3):hover {
  height: 24px;
  background-image: url("https://autoklik.me/icons/bigMenuAddd.png");
}
.bigMenu2 > a:nth-child(4):hover {
  height: 24px;
  background-image: url("https://autoklik.me/icons/bigMenuPorukee.png");
}
.bigMenu2 > a:nth-child(5):hover {
  background-image: url("https://autoklik.me/icons/bigMenuProfill.png");

  height: 24px;
}

.bigMenu2 > a:hover,
.bigMenu3 a:hover {
  background-size: contain; /* Podešavamo veličinu pozadinske slike */
  background-repeat: no-repeat; /* Onemogućavamo ponavljanje pozadinske slike */
  background-position: left; /* Postavljamo poziciju pozadinske slike */
  display: flex;
}
.language {
  display: flex;
  gap: 10px;
  cursor: pointer;
  color: white;
}
.language img {
  width: 1rem;
}
.strelicaJezik {
  width: 0.7rem !important;
  height: 0.6rem;
  padding-top: 4px;
}
.jeziciIcons {
  width: 1.5rem;
}
.selectLanguage {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 1rem;
  padding: 0.7rem;
  position: absolute;
  color: #141414;
  font-size: 0.9rem;
  border-radius: 7px;
  top: 48px;
  cursor: pointer;
  left: 0px;
  font-weight: 500;
}
.profilMore {
  border-top: 37px solid #4caf5000;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  animation: slideInFromBottom 0.2s ease-in-out forwards;
  border-radius: 7px;
  right: -60px;
  position: absolute;
  background-color: transparent;
  width: 196px;
  padding-left: 0;
  transition: padding-left 0.5s ease;
  z-index: 10;
  color: white !important;
  top: 52px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.kategorijePocetna .profilMore > a:hover,
.kategorijePocetna .oglasiMenuAds > a:hover {
  color: #c35d79;
}
.kategorijePocetna .profilMore > a,
.kategorijePocetna .oglasiMenuAds > a {
  color: #232323;
  border-bottom: 1px solid #dcdcdc;
  background-color: #ffffff;
}
.oglasiMenuAds {
  animation: slideInFromLeft ease-out 0.5s forwards;
  box-shadow: 0 0 2px 0 #717171;

  position: absolute;
  background-color: transparent;
  width: 196px;
  padding-left: 0;
  transition: padding-left 0.5s ease;

  color: white !important;

  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  right: -257px;
  top: 90px;
}
.profilMore a:hover,
.oglasiMenuAds a:hover {
  padding-left: 27px;
  transition: padding-left 0.8s ease;
  text-decoration-line: underline;
}
.profilMore > a:first-child,
.oglasiMenuAds > a:first-child {
  padding-top: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.profilMore > a:last-child,
.oglasiMenuAds > a:last-child {
  padding-bottom: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.oglasiMenuAds > a {
  border-bottom: 1px solid #3c3c3c;
  background-color: black;
  cursor: pointer;
  padding: 15px 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3c3c3c;
}
.profilMore > a {
  border-bottom: 1px solid #3c3c3c;
  background-color: black;
  cursor: pointer;
  padding: 15px 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.moreInfo {
  opacity: 1 !important;
  width: 1rem;
  transition: none !important;
}

.moreInfo:hover {
  pointer-events: none !important;
}
.profilMore:hover {
  color: rgb(255, 255, 255);
}
.selectLanguage:before {
  content: "";
  position: absolute;
  top: -18px;
  right: 55px;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 21px solid white;
}
@media screen and (max-width: 999px) {
  .bigMenu {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .bigMenu {
    padding: 0 8vh;
  }
}

@media screen and (max-width: 1300px) {
  .bijeliLogo {
    width: 7rem;
  }
  .searchMenu {
    width: 1.5rem;
  }
  .bigMenuIcons {
    width: 1.2rem;
  }
  .language img {
    width: 0.8rem;
    height: 0.8rem;
  }

  .strelicaJezik {
    width: 0.5rem !important;
    height: 0.5rem !important;
    padding-top: 3px;
  }
}
@media screen and (max-width: 1200px) {
  .bigMenu2 {
    /* Vaši stilovi ispod 1200px */
    gap: 30px;
  }
  .bijeliLogo {
    width: 6rem;
  }
}
