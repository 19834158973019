.hrr {
  border-bottom: 2px solid #e3e5ed;
  margin: 0.9rem;
}
.odjavaAsk {
  background-color: #ffffff;
  position: absolute;
  top: 60%;
  color: rgb(36, 36, 36);
  padding: 2rem;
  z-index: 75;
  left: 0.9rem;
  right: 0.9rem;
  bottom: 0;
  right: 0;
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
     .zatamniProfil {
    
      background-color: #ffffffd6;
      z-index: 7;
      height: 100vh;
      filter: brightness(.5);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    
  }
.odjavaAsk p{
  
    text-align-last: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1rem;

}
.odjavaAsk div{
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.hideProfil{
  display: none;
}
#showProfil{

}





@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}


.zatamniProfil,.odjavaAsk {
  animation: slideInFromBottom 0.7s ease-in-out forwards;
}
.odjavaDa{
  border: 1px solid #C35D79;
  width: 5rem;
  height: 2.5rem;
  border-radius: 7px;
  color:#C35D79;
  display: grid;
  place-content: center;
  font-size: .9rem;
  font-weight: 600;
}
.paddingJezik{
  padding-bottom: 3rem;

}
.closeLanguageProfil {
  animation: slideInFromBottom 0.7s ease-in-out forwards;
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  z-index: 999;
  width: 1.5rem;
}
.selectLanguageProfil {
      animation: slideInFromBottom 0.7s ease-in-out forwards;
  height: 220px;
  box-shadow: 1px 1px 20px 0px #797979;
  align-items: center;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 2rem;
  padding: 50px 70px 21px 19px;
  position: absolute;
  z-index: 998;
  color: #141414;
  font-size: 1.2rem;
  border-radius: 7px;
  top: 30.5%;
  cursor: pointer;
  left: 35%;
  font-weight: 500;
}
.odjavaNe{
  background-color:  #C35D79;
  width: 5rem;
  height: 2.5rem;
  border-radius: 7px;
  color:white;
  display: grid;
  place-content: center;
  font-size: .9rem;
  font-weight: 600;
}
.profilPocetna{
  display: none;
}

@media screen and (max-width:777) {


}
@media screen and (min-width: 777px) {
  .profilPocetna{
    display: block;
}
  .profil {
display: none !important;
    padding-top: 5rem;
  }
.profil > .tabbar{
  display: none;
}
}
@media screen and (max-width: 450px) {
.selectLanguageProfil {    left: 23%;}}

@media screen and (min-width: 800px) {
  .profilnaSlika {
    /* Stilovi za profilnu sliku */
    width: 6.5rem;
    height: 6.5rem;
  }
  
  .imeUser {
    font-size: 1.4rem;
  }
  
  .brojTelefonaUser {
    font-size: 1rem;
  }
  .jezikLink {
    display:none;

  }
  .izmijeniP {
    font-size: 1rem;
  }
  
  .statusOglasa {
    font-size: 1rem;
  }
  
  .mojiOglasiNaslov {
    font-size: 1.2rem;
  }
  
  .profil {
    display: grid;
    grid-template-columns: 770px;
    justify-content: center;
  }
}
