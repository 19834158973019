.cijenaDiv,
.godisteDiv,
.cm3Div,
.kmDiv {
  display: flex;
}

.cijenaDiv {
  margin-bottom: 0.5rem;
}
.lokacijaP {
  padding-bottom: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #5a5a65;
}
.inputiFilter .kontaktPodaci {
  margin: 0;
}

.cijenaInput,
.godisteInput,
.duzinaInput,
.sirinaInput,
.brVrataInput,
.kwInput,
.ksInput,
.brSatiInput,
.kmInput,
.cm3Input {
  position: relative;
}

.cijene,
.godisteDiv,
.cm3Div,
.duzinaDiv,
.brSatiDiv,
.sirinaDiv,
.kwDiv,
.cijenaDiv,
.brVrataDiv,
.kmDiv,
.ksDiv {
  width: 100%;
  display: grid;

  gap: 2%;
  grid-template-columns: 49% 49%;
}
.ksOd,
.godisteOd,
.godisteDo,
.ksDo,
.brSatiOd,
.brSatiDo,
.kwOd,
.kmOd,
.kmDo,
.cm3Od,
.brVrataOd,
.brVrataDo,
.cm3Do,
.kwDo,
.cijenaDo,
.cijenaOd,
.duzinaOd,
.sirinaOd,
.duzinaDo,
.sirinaDo {
  background-color: #f2f3f5;
  border-radius: 7px;
  height: 2.5rem;
  align-items: center;
  width: auto;
  padding-left: 10px;
  font-size: 0.8rem;
  color: #5a5a65;
  border: none;
}
.cijenaInput,
.ksInput,
.brSatiInput,
.cm3Input,
.godisteInput,
.kwInput,
.duzinaInput,
.sirinaInput {
  position: relative;
}
.cijenaP,
.ksP,
.brSatiP,
.duzinaP,
.sirinaP {
  font-size: 0.8rem;
  font-weight: 600;
  color: #5a5a65;
}
.autoFilterContainer > .cijenDodajOglasContainer {
  margin: 0 0.5rem 0 0.9rem;
}
.kwP,
.kpp,
.godisteP,
.kmP,
.cm3P,
.brVrataP,
.brSatiP,
.cijenaDodajOglasP,
.cijenaP,
.ksP,
.duzinaP,
.sirinaP {
  color: rgb(255, 255, 255);
  font-weight: 700;
  place-content: center;
  place-items: center;
  display: flex;
  text-align-last: center;
  font-size: 0.8rem;
  position: absolute;
  margin: 1px;
  z-index: 1;
  top: 0rem;
  width: 5.7rem;
  right: 0;
  background-color: #c35d79;
  height: 2.5rem;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.kwNaslov,
.ksNaslov,
.godisteNaslov,
.cm3Naslov,
.cijenaNaslov,
.duzinaNaslov,
.sirinaNaslov {
  font-size: 0.8rem;
  font-weight: 600 !important;
  color: #5a5a65 !important;
}
.podDivZamjena {
  position: relative;
  display: flex;
  gap: 1vw;
  width: max-content;
}
.naslovZamjena {
  font-size: 0.8rem;
  font-weight: 600;
  color: #5a5a65;
}

.ksDiv,
.kwDiv,
.cm3Div,
.brSatiDiv,
.brVrataDiv,
.tabliceContainer,
.kmDiv {
  margin-top: 0.5rem;
}
.duzinaDiv,
.sirinaDiv {
}
.cijenDodajOglasContainer {
  margin: 0 0.9rem;
}

.pocetnaContainer .btnBckg:active {
  color: white;
}
.pocetnaContainer .duzinaDiv,
.pocetnaContainer .sirinaDiv {
  margin-bottom: 0;
}
.pocetnaContainer .tabliceContainer {
  margin: 0;
}
.pretragaBckg .brSatiDodajOglas {
  margin-bottom: 0;
}
.nautikaPretraga2 .btnBckg {
  grid-column-end: span 3;
}
.pretraga2 {
  z-index: 5 !important;
  grid-template-columns: 1fr 1fr 1fr;
}
.selectedKategorija,
.selectKategorija {
  width: 2.5rem;
  height: 2rem;
  padding: 0.3rem 0.85rem;
}

.gumefelne {
  cursor: pointer;
}
.kategorijePocetna
  .divKategorijaPretraga
  .LinkBorderRight
  .nazivKategorija
  .selectedKategorija,
.kategorijePocetna
  .divKategorijaPretraga
  .LinkBorderRight
  .nazivKategorija
  .selectKategorija {
  width: 2rem;
  height: 2rem;
  padding: 0.3rem 0.55rem;
}

.hideMenu {
  display: none;
}
.divKategorijaPretraga {
  margin: 0.9rem;

  display: flex;
  overflow: auto;
}
.selectedKategorija {
  background-color: #c35d79;
  border-radius: 13px;
}
.selectKategorija {
}
.kategorijePocetna div .divKategorijaPretraga {
  border-radius: 2px;
}
.LinkBorderRight,
.LinkSelect {
  display: flex;
  place-content: center;
  align-items: center;
  background-color: rgb(235, 235, 235);
}
.LinkBorderRight .borderRight {
  width: 1px;
  height: 1.3rem;
}
.naslovPretrageKategorija {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  color: #3c3c3c;
  padding: 1.5rem 0 0rem 0;
}
.deleteIcons {
  width: 1.6rem;
  top: 1.35rem;
  right: 1rem;
  position: absolute;
  height: 1.6rem;
}

.headerPretragaKategorija {
  position: relative;
}
.selectedColor {
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInBackground {
  from {
    background-color: transparent;
  }
  to {
    background-color: #c35d79;
  }
}

.nazivKategorija.selectedColor {
  animation: fadeInBackground 1.3s ease forwards;
}
.divKategorijaPretraga {
  border-radius: 13px;
}
@media screen and (min-width: 500px) {
  .kategorijePocetna
    .divKategorijaPretraga
    .LinkBorderRight
    .nazivKategorija
    .selectedKategorija,
  .kategorijePocetna
    .divKategorijaPretraga
    .LinkBorderRight
    .nazivKategorija
    .selectKategorija {
    width: 3rem;
    height: 2.5rem;
    padding: 0.3rem 0.55rem;
  }
}

@media screen and (max-width: 777px) {
  .selectKategorija {
    border-radius: 13px;
  }

  .nazivKategorija p {
  }
  .pocetnaContainer .nazivKategorija p {
  }
  .bckgImg {
    display: none;
  }
  .menu {
    display: none;
  }

  .pretraga4,
  .pretraga1,
  .pretraga2,
  .pretraga3,
  .pretraga4,
  .pretraga5,
  .pretraga6,
  .pretragaBckg {
    display: none;
  }
  .pretraga4 {
  }
}
@media screen and (min-width: 777px) {
  .pretragaBckg .hpInput,
  .pretragaBckg .kmInput,
  .pretragaBckg .ksInput,
  .pretragaBckg .duzinaInput,
  .pretragaBckg .sirinaInput,
  .pretragaBckg .brSatiInput,
  .pretragaBckg .cijenaInput,
  .pretragaBckg .duzinaInputDodajOglas,
  .pretragaBckg .sirinaInputDodajOglas,
  .pretragaBckg .cijenaDodajOglasInput,
  .pretragaBckg .kwInput,
  .pretragaBckg .godisteInput,
  .pretragaBckg .cm3Input {
    padding-left: 0;
    border-radius: 15px;
  }
  .pretragaBckg .kmOd,
  .pretragaBckg .godisteOd,
  .pretragaBckg .godisteDo,
  .pretragaBckg .brSatiOd,
  .pretragaBckg .brSatiDo,
  .pretragaBckg .kwOd,
  .pretragaBckg .pretragaBckg .kmOd,
  .pretragaBckg .kmDo,
  .pretragaBckg .cm3Od,
  .brVrataOd,
  .brVrataDo,
  .pretragaBckg .cm3Do,
  .pretragaBckg .kwDo,
  .pretragaBckg .cijenaDo,
  .pretragaBckg .cijenaOd,
  .pretragaBckg .duzinaOd,
  .pretragaBckg .sirinaOd,
  .pretragaBckg .duzinaDo,
  .pretragaBckg .sirinaDo {
    background-color: #ffffff;
    padding-left: 4px !important;
    font-weight: 100;
    border-radius: 7px;
    border: 1px solid #d0d0d07a;
  }
  .pretragaBckg .materijalSelect,
  .pretragaBckg .nosivostSelect,
  .pretragaBckg .brSJedistaSelect,
  .pretragaBckg .tipSelect,
  .pretragaBckg .markaSelect,
  .pretragaBckg .modelSelect,
  .pretragaBckg .karoserijaSelect,
  .pretragaBckg .gorivoSelect,
  .pretragaBckg .zamjenaSelect {
    height: 44px;
    padding-left: 7px;
    font-size: 0.8rem;
    border-radius: 7px;
    font-weight: 100;
    border: 1px solid #d0d0d07a;
    background-color: white;
    color: #7a7a7a;
  }
  .pretragaBckg .materijalSelect,
  .pretragaBckg .nosivostSelect,
  .pretragaBckg .brSJedistaSelect,
  .pretragaBckg .oznakaInput,
  .pretragaBckg .tipSelect,
  .pretragaBckg .cijenaInput,
  .pretragaBckg .zamjenaSelect,
  .pretragaBckg .gorivoSelect,
  .pretragaBckg .markaSelect,
  .pretragaBckg .modelSelect,
  .pretragaBckg .karoserijaSelect {
    margin-bottom: 0 !important;
  }

  .pretraga1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .pretragaBckg .kwP,
  .pretragaBckg .kpp,
  .pretragaBckg .godisteP,
  .pretragaBckg .kmP,
  .pretragaBckg .cm3P,
  .pretragaBckg .brVrataP,
  .pretragaBckg .brSatiP,
  .pretragaBckg .cijenaDodajOglasP,
  .pretragaBckg .cijenaP,
  .pretragaBckg .ksP,
  .pretragaBckg .duzinaP,
  .pretragaBckg .sirinaP {
    width: fit-content;
    height: 2.55rem;
    padding: 0 2px;
  }
  .kwDiv,
  .cm3Div,
  .kmDiv {
    margin-top: 0 !important;
  }
  .ksOd,
  .godisteOd,
  .godisteDo,
  .ksDo,
  .brSatiOd,
  .brSatiDo,
  .kwOd,
  .kmOd,
  .kmDo,
  .cm3Od,
  .brVrataOd,
  .brVrataDo,
  .cm3Do,
  .kwDo,
  .cijenaDo,
  .cijenaOd,
  .duzinaOd,
  .sirinaOd,
  .duzinaDo,
  .sirinaDo {
  }
  .pocetnaContainer .nazivKategorija p {
    display: none;
  }
  .pocetnaContainer .divKategorijaPretraga {
    background-color: transparent;
  }
  .divKategorijaPretraga {
    background-color: #f2f3f5;
    border-radius: 2px;
    overflow: hidden;
    width: 723px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 4rem auto 0 auto;
  }
  .bckgImg {
    width: 100%;
    display: flex;
    height: 100%;
    filter: brightness(0.7);

    position: relative;
  }
}

@media screen and (min-width: 776px) {
  .pretragaDefault {
    grid-template-columns: 1fr 1fr;
    border-radius: 7px;
    gap: 7px;
    border-top: 1px solid #ebebeb;
    box-shadow: 0px 1px 3px 0px #ebebeb;
    z-index: 0;
    position: absolute;
    display: grid;
    background-color: white;
    padding: 1rem 1rem;
    flex-direction: column;
    height: 198px;
    left: 21px;
    top: 162px;
    width: 700px;
  }
}

@media screen and (min-width: 777px) {
  .selectKategorija + p {
    color: white;
    height: 1.5rem;
    background-color: rgb(174 224 224);
    width: 100%;
    display: grid;
    place-items: center;
  }
  .selectedKategorija + p {
    text-align: center;
    height: 1.5rem;
    color: #c35d79;
    background-color: white;
    width: 100%;
    display: grid;
    place-items: center;
  }
  .pretragaAutmomobiliContainer {
    display: grid;
    justify-content: center;
    grid-template-columns: 776px;
  }
  .selectedKategorija,
  .selectKategorija {
    width: 4rem;
    height: 3.5rem;
    padding: 0.5rem 2.45rem;
  }
  .selectedKategorija {
    border-radius: 0;
  }
  .pocetnaContainer .headPhone {
    display: none;
  }

  .pocetnaContainer .hr {
    display: none;
  }
  .kategorijePocetna .sveKategorije {
    display: none;
  }
  .kategorijePocetna div .divKategorijaPretraga {
    display: flex !important;
    gap: 3px;
    overflow: hidden;
  }

  .kategorijePocetna
    .divKategorijaPretraga
    .LinkBorderRight
    .nazivKategorija
    .selectedKategorija,
  .kategorijePocetna
    .divKategorijaPretraga
    .LinkBorderRight
    .nazivKategorija
    .selectKategorija {
    width: 2rem;
    height: 2rem;
    padding: 0.3rem 0.55rem;
  }

  .pocetnaContainer .relative .divKategorijaPretraga .LinkBorderRight {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .kategorijePocetna div .divKategorijaPretraga {
    position: absolute;
    top: 110px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 1rem 0.9rem 0rem 0.9rem;
    left: 43px;
  }

  .pretraga6 {
    border-radius: 7px;
    gap: 7px;
    border-top: 1px solid #ebebeb;
    z-index: 1;
    position: absolute;
    display: flex;
    background-color: white;
    padding: 1rem 1rem;
    flex-direction: column;
    width: 700px;
    height: 240px;
    left: 21px;
    top: 166px;
  }

  .pretraga1,
  .pretraga2,
  .pretraga3,
  .pretraga4,
  .pretraga5 {
    width: 700px;
    border-radius: 7px;
    gap: 7px;
    border-top: 1px solid #ebebeb;
    box-shadow: 0px 1px 3px 0px #ebebeb;
    z-index: 1;
    position: absolute;
    display: grid;
    background-color: white;
    padding: 1rem 1rem;
    flex-direction: column;
    height: auto;
    left: 21px;
    top: 162px;
  }
  .selectedColor {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .pocetnaContainer .LinkBorderRight,
  .pocetnaContainer .LinkSelect {
    background-color: rgb(255 255 255);
  }
  .pretraga2 {
    padding: 0.5rem 1rem;
  }
  .markaBckg,
  .modelBckg,
  .godisteBckg,
  .cijenaBckg,
  .karoserijaBckg {
    height: 2.5rem;
    padding: 0 0.5rem;
    border-radius: 7px;
    border: 1px solid #d0d0d07a;
    background-color: white;
    color: #7a7a7a;
  }
  .pretraga6 {
    height: fit-content;
  }
  .menu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 98;

    left: 0;
    background-color: #ffffff;
    place-items: center;
    border-bottom: 1px solid #d8d8d8;
    height: 3rem;
    padding: 0.9rem;
    display: flex;
    justify-content: space-between;
  }
  .menu-hide {
    display: none;
  }
  .menuLogo {
    width: 7rem;
    height: 3rem;
  }
  .menuBtn {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
  }
  .btnBckg {
    height: 2.5rem;
    border: none;

    margin-top: 7px;
    border-radius: 7px;
    font-size: 0.9rem;
    font-weight: 600;
    color: white;
    display: flex;
    place-items: center;
    grid-column-end: span 3;
    justify-content: center;
    gap: 3px;
    background-color: #c35d79;
  }
  .searchBckg {
    width: 1rem;
  }
  .gumefelne {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    place-items: center;
    /* background-color: #f2f3f5; */
    /* border-radius: 7px; */
    /* margin-bottom: 0.5rem; */
  }
  .tocak1,
  .tocak2,
  .tocak3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .zatvoriZamraceniDiv {
    opacity: 0;
  }
  .zamraceniDiv {
    position: absolute;
    top: -15px;
    bottom: 0;
    right: 0;
    z-index: 999;
    left: 0;
    background-color: #0000008f;
    height: 101.5vh;
    opacity: 1;
    transition: opacity 0.4s ease;
  }
  .openMenu {
    position: absolute;
    top: -23px;
    bottom: 0;
    right: 28.5rem;
    left: 0;
    height: 102vh;
    cursor: pointer;
  }
  .closeMenu {
    left: 17.5rem;
    cursor: pointer;
    z-index: 1000;
    position: absolute;
    top: 1.5rem;
    width: 1.5rem;
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(+100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .openMenu {
    z-index: 1000;
    animation: slideInFromLeft 0.3s ease-in-out forwards;
  }

  .hideMenu {
    display: none;
    animation: slideInFromRight 0.3s ease-in-out forwards;
  }
  .logoLeftMenu {
    z-index: 1000;
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    width: 7rem;
    height: 3rem;
  }
  .openMenu .logoLeftMenu {
    position: absolute;
    top: 2.5rem;
    left: 1rem;
    width: 7rem;
    height: 3rem;
  }
  .openMenu .closeMenu {
    top: 2.7rem;
  }
  .openMenu .tabbar {
    top: 20px;
  }
}

@media screen and (min-width: 900px) {
  .pretraga1,
  .pretraga2,
  .pretraga3,
  .pretraga4,
  .pretraga5,
  .pretragaDefault,
  .pretraga6 {
    width: 826px;
  }
  .pretragaBckg .kwP,
  .pretragaBckg .kpp,
  .pretragaBckg .godisteP,
  .pretragaBckg .kmP,
  .pretragaBckg .cm3P,
  .pretragaBckg .brVrataP,
  .pretragaBckg .brSatiP,
  .pretragaBckg .cijenaDodajOglasP,
  .pretragaBckg .cijenaP,
  .pretragaBckg .ksP,
  .pretragaBckg .duzinaP,
  .pretragaBckg .sirinaP {
    padding: 0 4px;
  }
}
@media screen and (min-width: 960px) {
  .pretragaDefault,
  .pretraga4,
  .pretraga2,
  .pretraga1,
  .pretraga6 {
    top: 249px;
    left: 50px;
  }
  .kategorijePocetna div .divKategorijaPretraga {
    top: 192px;
    left: 70px;
  }
}

@media screen and (min-width: 1142px) {
  .pretragaDefault,
  .pretraga2,
  .pretraga1,
  .pretraga6,
  .pretraga4 {
    top: 292px;
    left: 111px;
  }
  .kategorijePocetna div .divKategorijaPretraga {
    top: 236px;
    left: 123px;
  }
}
@media screen and (min-width: 1300px) {
  .pretraga6,
  .pretraga2,
  .pretraga1,
  .pretragaDefault,
  .pretraga4 {
    top: 371px;
    left: 151px;
  }
  .kategorijePocetna div .divKategorijaPretraga {
    top: 316px;
    left: 162px;
  }
}
@media screen and (min-width: 1500px) {
  .pretraga1,
  .pretragaDefault,
  .pretraga4 {
    top: 73%;
    transform: translate(-50%, -50%);
    left: 779px;
  }
  .pretraga6 {
    left: 779px;
    transform: translate(-50%, -50%);
    top: 77.1%;
  }
  .pretraga2Big {
    height: 245px !important;
    top: 75% !important;
  }
  .pretraga2Big {
    .pretraga2Big {
      height: 14rem;
      top: 76% !important;
    }
  }
  .pretraga2 {
    top: 72%;
    left: 779px;
    transform: translate(-50%, -50%);
  }
  .kategorijePocetna div .divKategorijaPretraga {
    transform: translate(-66%, -50%);
    top: 52%;
    left: 848px;
  }
}
@media screen and (min-width: 1496px) {
}
@media screen and (min-width: 1357px) {
  .bckgImg {
    width: 100%;
    height: 725px;
    object-fit: cover;
  }
}
@media screen and (min-width: 1000px) {
  .menu {
    display: none;
  }
}
