.obaveznoPolje {
  position: absolute;
  color: red;
  top: -2px;
  right: -8px;
}
.naslovKategorija {
  font-size: 0.9rem;
  font-weight: 600;
  color: #5a5a65;
}
.podDivKategorija {
  position: relative;
  display: flex;
  gap: 1vw;
  width: -webkit-fill-available;
}
.kategorijePcP {
  font-size: 1.1rem;
  padding-bottom: 1rem;
  font-weight: 600;
  padding-left: 0.9rem;
  color: #4a4a4a;
}
.kategorije {
  margin: 0 0.9rem;
  position: relative;
}
.izaberiKategoriju {
  background-color: #f2f3f5;
  display: flex;
  justify-content: space-between;
  /* padding: 4.5vw 5vw; */
  border-radius: 1vw;
  height: 3.5rem;
  margin-top: 0.5rem;
  align-items: center;
  padding: 0 3vw;
}
.izaberiKategorijuP {
  font-size: 0.8rem;
  font-weight: 600;
  color: #5a5a65;
}
.kategorijaDiv {
  margin-bottom: 0.7rem;
}
.kategorijaSelect {
  background-color: #f2f3f5;

  border-radius: 7px;

  height: 42px;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  color: #5a5a65;
  border: none;
}
.kategorijePcImg {
  width: 4rem;
}
.kategorijePc {
  margin: 0.9rem;
  gap: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
}
.kategorijePc div {
  border: 1px solid #c6c6c6;
  display: grid;
  background-color: #f2f3f5;
  place-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.kategorijePc div p {
  color: #464646;
  font-size: 0.83rem;
  font-weight: 500;
}
.kamionVeliki {
  width: 8rem;
  height: 64px;
}
.filterKategorija {
  filter: brightness(0.8);
}
@media screen and (max-width: 798px) {
  .kategorijePc {
    display: none;
  }
}
