.recenzije{
    background-color: white;
    padding: 1.5rem;

    margin: 0.9rem;
    border-radius: 10px;
}
.errorStar,.errorKoment{
    color: red;
  
    font-size: .7rem;
    font-weight: 500;
    padding-left: 2px;
}
.recenzijaDone{
    font-size: 1rem;
    padding-top: 7px;
    font-weight: 500;
    font-weight: 600;
    color: #c35d79;
}
.komentar{
    height: 9.563rem;
    margin-top: 0.7rem;
    width: -webkit-fill-available;
    background-color: #f2f3f5;
    border: none;
    padding-top: 0.5rem;
    font-weight: 600;
   
    border-radius: 7px;
    padding-left: 10px;
    color: #5a5a65;
    font-size: 0.9rem;
}
.recenzijeKupaca{
    margin: 0.9rem;
        background-color: white;
        padding: 1.5rem;

        border-radius: 10px;}
    .recenzijaKomentar{
        font-size: 14px;
        color: #5f6973;
        font-weight: 500;
    }
    .recenzijaStar {
        position: absolute;
        width: 6rem;
        right: 4px;
        top: -59px;
    }
    
.recenzije .prodavac{
    display: none;
}
.recenzijaImg {
    object-fit: cover;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
}
.recenzijaIme {
    font-size: 17px;
    font-weight: 600;
    color: #272727;
}
.recenzijaDatum{
   color: #272727;
    font-size: 13px;
    font-weight: 500;
}
.recenzijaDivVrijeme {
    display: flex;
    padding-top: 0.4rem;
    position: relative;
    padding-bottom: 0.7rem;
}
.recenzijeKupaca > div {
    border-bottom: 2px solid #c35d79;
    padding-bottom: 7px;
    padding-top: 7px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 8fr;
}
.recenzijaBtn{
    margin-top: 1rem;
    background-color: #c35d79;
    width: 100%;
    display: flex;
    border: none;
    cursor: pointer;
    text-align: center;
    height: 3.5rem;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    gap: 7px;
    justify-content: center;
    border-radius: 7px;
}
.recenzije .prodavac{
font-size: 18px;
    /* font-style: normal; */
    font-weight: 600;}
    .recenzijeHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .naslovRecenzije{
        font-size: 18px;
        padding-bottom: 1.5rem;
    font-weight: 600;
    }
    @media screen and (max-width: 500px) {
        .naslovRecenzije {
          font-size: 15px;
      }
      .komentar{
          font-size: .7rem;}
      
      .ratingStars {
          padding: 0;}
      .recenzijaBtn{font-size: .85rem;}
      .recenzijaImg {
          width: 4rem;
          height: 4rem;
      }
      
      .recenzijaDivVrijeme {
          display: flex;
          padding-top: 4px;
          position: relative;
          padding-bottom: 7px;
      }
      .recenzijaIme {
          font-size: 14px;}
      .recenzijeKupaca > div {
        padding: 0.7rem 0;
        border-bottom: 2px solid #c35d79;
          gap: 6px;}
      .recenzijaKomentar {
          font-size: 13px;}}