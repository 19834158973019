.slikaUporedi {
  object-fit: cover;
  width: 100%;
  border-radius: 7px;
  height: 5rem;
}
.uporedjivanjeVozila .headPhone {
  padding: 0.9rem;
}
.uporedjivanjeVozilaDiv {
  display: flex;
  flex-direction: column;
  background-color: hsl(0, 0%, 100%);

  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  padding: 0.2rem 0.9rem 0.3rem 0.9rem;
}
.markaModelUporedi,
.karoserijaUporedi,
.godisteUporedi,
.bojaUporedi,
.gorivoUporedi,
.ostecenjeUporedi,
.zapreminaUporedi,
.tabliceUporedi,
.ksUporedi,
.cijenaUporedi,
.regUporedi,
.kmUporedi,
.zamjenaUporedi,
.mjenjacUporedi,
.karoserijaUporedi {
  border-bottom: 1px solid #dfdfdf;
  align-items: center;
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  color: rgb(119, 119, 119);
}
.slika {
  margin-bottom: 1rem;
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 4fr 1fr 4fr;
}
.uporedjivanjeVozila {
  background-color: #dfdfdf;
  padding-bottom: 1rem;
}
.stavkeUporedi {
  place-content: center;
  font-size: 0.85rem;
  font-weight: 600;
  height: 3rem;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
}
.prvoVozilo,
.drugoVozilo,
.prvoVozilokm,
.drugoVozilokm,
.prvoVozilocijena,
.drugoVozilocijena {
  font-size: 0.75rem;
  font-weight: 600;
  height: 3rem;
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}
.drugoVozilo,
.drugoVozilokm,
.drugoVozilocijena {
  height: 3rem;
  display: flex;
  justify-content: right;
}
.slikaUporediP {
  place-self: center;
  font-size: 0.85rem;
  font-weight: 600;
}
.jacePrvo {
  background: linear-gradient(to left, rgb(133, 255, 133), #ffffff);
}
.slabijePrvo {
  background: linear-gradient(to left, rgb(255, 119, 119), #ffffff);
}
.jaceDrugo {
  background: linear-gradient(to right, rgb(133, 255, 133), #ffffff);
}
.slabijeDrugo {
  background: linear-gradient(to right, rgb(255, 119, 119), #ffffff);
}
.neutralnoPrvo {
  background: linear-gradient(to left, rgb(199, 199, 199), #ffffff);
}

.neutralnoDrugo {
  background: linear-gradient(to right, rgb(199, 199, 199), #ffffff);
}

.uporedjivanjeVozila .headPhone {
  margin: 0;
  background-color: white;
  border-bottom: 1px solid rgb(199, 199, 199);
}
.uporedjivanjeVozila .headPhone {
  padding: 0.9rem 0.9rem 0rem 0.9rem;
}
.zamjenaUporedi {
  border: none;
}
.dodatneInformacijeUporedi {
  margin: 0.9rem;
  background-color: white;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-radius: 15px;
}
.opremaVozilo2 {
}
.opremaVozilo2 p {
  font-size: 0.75rem;
  font-weight: 600;

  color: rgb(119, 119, 119);
  justify-content: right;
  align-items: center;
  display: flex;
  flex-direction: row;

  width: -webkit-fill-available;
  padding-right: 5px;
}
.opremaVozilo1 p {
  font-size: 0.75rem;
  font-weight: 600;

  color: rgb(119, 119, 119);
  padding-left: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;

  width: -webkit-fill-available;
}
.dodatnaOpremaDiv {
  justify-content: space-between;
  display: flex;
}
.dodatnaOpremaUporedi,
.naslovOpisUporedi {
  font-size: 1rem;
  font-weight: 600;
  color: #454545;

  text-align: center;
  padding: 0.6rem 0;
}
.opremaItem {
  display: flex;
}
.opremaVozilo1,
.opremaVozilo2 {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.opisUporedi {
  margin: 0.9rem;
  background-color: white;
  border-radius: 15px;
}
.opisUporediDiv {
  display: flex;
}
.opisUporedi2 p {
}
.opisUporedi2 {
  font-size: 0.75rem;
  font-weight: 600;
  color: rgb(119, 119, 119);
  padding-left: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
}
.opisUporedi1 {
  font-size: 0.75rem;
  font-weight: 600;
  color: rgb(119, 119, 119);
  padding-left: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;

  width: -webkit-fill-available;
}
.kontaktUporedi div {
  display: flex;
}
.kontaktUporedi {
  display: grid;
  justify-content: space-between;
  background-color: white;
  margin: 0.9rem;
  padding: 0.4rem;
  gap: 14px;
  border-radius: 15px;
  grid-template-columns: 1fr 2px 1fr;
}
.opisUporedi1,
.opisUporedi2 {
  background-color: #dfdfdf;
  padding: 0.4rem;
  margin: 0.3rem;
  border-radius: 5px;
  color: rgb(51, 51, 51);
}
.kontaktUporedi .relative {
  flex-direction: column;
}
.imeUporedi {
  align-self: center;
  color: #454545;

  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 3px;
}
.hrUporedi {
  margin: 1rem 0;
  border-bottom: 1px solid #e3e5ed;
}
.userPhotoUporedi {
  border-radius: 100%;
}
.prosjecnaOcjenaPUporedi {
  font-size: 0.75rem;
  font-weight: 600;
}
.averStarUporedi {
  width: 14px;
}
.brojUporedi {
  color: #c35d79;
  font-size: 0.8rem;
  font-weight: 600;
}
.borderUporedi {
  border-left: 1px solid #e3e5ed;
}
@media screen and (min-width: 500px) {
  .lokacijaPAds {
    color: #c35d79;
    place-self: center;
    font-size: 16px;
    font-weight: 600;
  }
  .uporedjivanjeVozilaDiv .lokacijaIcons {
    width: 1.3rem;
    height: 1.3rem;
  }
  .slikaUporedi {
    width: 100%;
    border-radius: 7px;
    height: 12rem;
    object-fit: cover;
  }
  .stavkeUporedi {
    font-size: 0.95rem;
  }
  .prvoVozilo,
  .drugoVozilo,
  .prvoVozilokm,
  .drugoVozilokm,
  .prvoVozilocijena,
  .drugoVozilocijena {
    font-size: 0.85rem;
  }
  .dodatnaOpremaUporedi,
  .naslovOpisUporedi {
    font-size: 1.3rem;
  }
  .opremaVozilo1 p {
    font-size: 0.85rem;
  }
  .correct {
    padding-left: 2px;
  }
  .opisUporedi1 {
    font-size: 0.85rem;
  }
  .imeUporedi {
    font-size: 1rem;
  }
  .brojUporedi {
    font-size: 1rem;
  }
  .slikaUporediP {
    font-size: 1rem;
  }
}

@media screen and (min-width: 700px) {
  .lokacijaPAds {
    font-size: 20px;
  }
  .uporedjivanjeVozilaDiv .lokacijaIcons {
    width: 1.5rem;
    height: 1.5rem;
  }
  .slikaUporedi {
    height: 17rem;
  }
  .stavkeUporedi {
    font-size: 1.15rem;
  }
  .prvoVozilo,
  .drugoVozilo,
  .prvoVozilokm,
  .drugoVozilokm,
  .prvoVozilocijena,
  .drugoVozilocijena {
    font-size: 0.95rem;
  }
  .dodatnaOpremaUporedi,
  .naslovOpisUporedi {
    font-size: 1.5rem;
  }
  .opremaVozilo1 p {
    font-size: 0.95rem;
  }

  .opisUporedi1 {
    font-size: 0.95rem;
  }
  .imeUporedi {
    font-size: 1.2rem;
  }
  .brojUporedi {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 777px) {
  .uporedjivanjeVozila .headPhone {
    display: none;
  }
  .uporedjivanjeVozila {
    margin-top: 5rem;
  }
}
@media screen and (min-width: 777px) {
  .uporedjivanjeVozila {
    margin-top: 7rem;
  }
}
@media screen and (min-width: 1000px) {
  .uporedjivanjeVozila {
    padding-top: 2rem;
  }
  .uporedjivanjeVozilaDiv {
    border-radius: 35px;
    padding-top: 1rem;
    margin: auto;
    width: 895px;
    display: flex;
    flex-direction: column;
    background-color: hsl(0, 0%, 100%);
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 2.2rem 1.9rem 0.7rem 1.9rem;
  }
  .uporedjivanjeVozila {
    margin-top: 5rem;
  }
  .opisUporedi {
    padding: 0.2rem 1.9rem 0.7rem 1.9rem;
    margin: auto;
    width: 895px;
    /* margin: 0.9rem; */
    background-color: white;
    border-radius: 15px;
  }
  .opisUporediDiv {
    gap: 25px;
  }
  .dodatneInformacijeUporedi {
    width: 895px;
    /* background-color: white; */
    padding: 0.2rem 1.9rem 0.7rem;
    margin: 1rem auto;
    background-color: white;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-radius: 15px;
  }
  .kontaktUporedi {
    width: 895px;
    background-color: white;
    padding: 1.2rem 1.9rem 0.7rem;
    margin: 1rem auto;
  }
  .uporedjivanjeVozila .prosjecnaOcjena {
    align-items: center;
    position: absolute;
    top: 4px;
  }
}
