.izmijeniPodatke {
  padding-top: 3rem;
  gap: 2vw;
  display: flex;
  align-items: center;

  flex-direction: column;
}
.profilnaEdit {
  position: relative;
}
.addPhoto {
  border: 4px solid white;
  bottom: 0.1rem;
  right: -6px;
  padding-bottom: 4px;
  background-color: #c35d79;
  color: white;
  width: 1.4rem;
  height: 1.2rem;
  border-radius: 20vw;
  font-size: 0.8rem;
  align-items: center;
  display: grid;
  position: absolute;
  font-weight: 700;
  font-size: 1rem;
  place-content: center;
  place-items: center;
}
.kontaktImeP {
  font-size: 0.9rem;
  font-weight: 600;
  color: #5a5a65;
  padding-bottom: 0.5rem;
}
.kontaktIme {
}
.kontaktImeInput {
  width: -webkit-fill-available;
  background-color: #f2f3f5;
  border: none;
  border-radius: 10px;
  height: 3.5rem;
  font-size: 0.9rem;
  color: #abacb6;
  font-weight: 500;
  padding-left: 10px;
  margin-bottom: 1rem;
}
.labelUpload {
  cursor: pointer;
}
.potvrdiIzmjene {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 1vw;
  width: 100%;
  gap: 1vw;
  background-color: #c35d79;
  height: 2.75rem;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 0.8rem;
}
.izmijeni {
  margin: 0.9rem;
  position: relative;
}
.backk {
  display: flex;
  align-items: center;
}
.brojTelefonaUser {
  font-weight: 700;
  color: #282424;
}
@media screen and (min-width: 777px) {
  .izmijeni {
    padding-top: 5rem;
  }
}
@media screen and (min-width: 800px) {
  .backk {
  }

  .izmijeni {
    display: grid;
    grid-template-columns: 770px;
    justify-content: center;
  }
}
