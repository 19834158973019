.likedPost .kocke {
  position: relative;
}
.sacuvaniOglasiHeader .back {
  margin: 0.9rem;
}
.likedPost .kocke {
  top: auto;
}
.likedPost .pcFilterSideDiv {
  grid-template-columns: 1fr;
}
.likedPost .pcFilterSide {
  display: none;
}
.dodajOglas .logoDodajOglas {
  margin: 0.9rem 0.9rem 0 0;
}
.likedPost div .bigMenu .zamraceniMenu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #393939;
  bottom: 0;
}

@media screen and (min-width: 777px) {
  .likedPost .kocke {
    position: absolute;
    top: 35px;
  }
  .likedPost {
    padding-top: 80px;
  }
  .likedpost div .openMenu .logoLeftMenu,
  .likedpost div .openMenu .closeMenu {
    z-index: 1000;
  }
  .tabbarHide {
    display: none;
  }
  .likedPost > .tabbar {
    display: none;
  }

  .noveObjaveH1 {
    display: block;
  }
}

@media screen and (max-width: 777px) {
  .likedPost .noveObjave {
    margin-top: 0rem;
  }
}
.nemaSacuvanih {
  position: fixed;
  width: -webkit-fill-available;
  height: 100vh;
  top: 77px;
  z-index: 999;
  background-color: white;
}
.likedPost .noveObjaveHeader {
  justify-content: space-between;
}
@media screen and (min-width: 1000px) {
  .likedPost .noveObjaveHeader {
    padding-top: 1rem;
  }
  .dodajOglas .dodajOglasDiv .objasnjenjeDiv {
    padding-top: 4rem;
  }
}
@media screen and (min-width: 1280px) {
  .likedPost .noveObjaveOglasi {
    grid-template-columns: 231px 231px 231px 231px;
    justify-content: left;
  }
  .likedPost .kocke {
    top: 27px;
  }
  .likedPost .reklameNoveObjave {
    display: none;
  }
}

@media screen and (min-width: 1500px) {
  .likedPost .noveObjaveOglasi {
    grid-template-columns: 231px 231px 231px 231px 231px;
    justify-content: left;
  }
}
