.headPhone {
  margin: 0.9rem 0.9rem 0px 0.9rem;
}

.searchInput {
  font-weight: 600;

  font-size: 0.7rem;
  padding-left: 0.2rem;
  width: 100%;
  height: 2.5rem;
  border: none;
  background-color: #eaeaea;
  border-radius: 1vw;
}

.searchIconsDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282424;
  width: 2.5rem;
  position: absolute;
  bottom: 0;
  top: 0;
  border-top-right-radius: 1vw;
  border-bottom-right-radius: 1vw;
  right: 0;
}
.searchIcons {
  width: 1.3rem;
}
.horizontal-line {
  width: 100%;
  height: 2px;
  background-color: #f2f3f5;
  margin: 0;
}
.search {
  display: grid;
  gap: 2%;
  grid-template-columns: 1fr 1fr 1fr;
}
.filterIcons {
  padding-right: 5%;
  width: 1.3rem;
  height: 1.3rem;
}
.searchDiv {
  height: 2.5rem;
  display: flex;
  position: relative;
}
.filterPretraga {
  align-items: center;
  display: flex;

  width: 100%;
  justify-content: space-between;
  height: 2.5rem;
  border: none;
  background-color: #eaeaea;
  border-radius: 1vw;
}
.pretragaP {
  font-size: 0.8rem;
  font-size: 0.7rem;
  font-weight: 600;
  color: #a3a3a3;
  padding-left: 4px;
}
.logoHome {
    place-self: center;
    width: 30vw;
    height: 3rem;
}
.logoHomeDiv {
  text-align-last: left;
  text-align: -webkit-center;
}
.long {
  padding: 0.9rem;
  /* padding-left: 0.9rem; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0rem;
  right: 0rem;
  position: absolute;
  z-index: 88;
  background-color: white;
}
.long .searchIconsDiv{
height: 2.5rem;
background-color: #c35d79;

}
.long .searchInput{
  width: 99%;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.long {
  animation: slideInFromLeft 0.7s ease-in-out forwards;
}

.headSearch{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.textPretraga {
  justify-self: right;
  font-size: .9rem;
  font-weight: 600;
  color: #3c3c3c;
  display: flex;
  background: right;
  align-items: center;
}
@media screen and (min-width: 500px) {

  .logoHome {
    place-self: center;
    width: 30vw;
    height: 3.5rem;
}}
@media screen and (min-width: 777px) {
  .logoHome {
    place-self: center;
    width: 30vw;
    height: 4.5rem;
}}