
.podaciProfil {
  display: flex;
  justify-content: space-between;
}
.ime {
  font-size: 1.25rem;
  font-weight: 700;
  color: #282424;
}
.brojTelefonaUser {
  font-size: 0.75rem;
  color: #282424;
  font-weight: 700;
}
.izmijeniP {
  color: #777886;
  font-size: 0.8rem;
  font-weight: 600;
}
.imeUser {
  font-size: 1.2rem;
  color: #282424;
  font-weight: 600;
  padding-bottom: 1vw;
}
.editIcons {
  width: 1.5rem;
  height: 1.5rem;
}
.editDiv {
  align-items: center;
  gap: 1vw;
  align-self: center;
  height: min-content;
  display: flex;
}
.imeBroj {
  align-self: center;
  padding-left: 0.7rem;
}
.displayFlex {
  display: flex;
}
.linkEdit {
  height: fit-content;
}
