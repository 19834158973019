.karticaPodaci .headerAktivni{
    padding-bottom: 2rem;
}
.karticaPodaci{
    padding: 0.9rem;
    background-color:  #F2F3F5;
    ;
    height: 100vh;
}
.podaciKartice{
    
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        gap: 15px;
        margin-bottom: 1rem;
}
.brKar,.imeKar{
    display: flex;
    flex-direction: column;
    font-size: .8rem;
    font-weight: 600;
    color: #777886;
}
.podaciKartice div input{
    height: 2.5rem;
    padding-left: 5px;
    border: none;height: 
    56px;
    box-shadow: 0px 4px 12px 0px #00000029;
color: black;
    font-size: 1rem;
    font-weight: 600;
   
}
.platiKartica{
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 10px;
    width: 100%;
    gap: 1vw;
    background-color: #c35d79;
    height: 2.75rem;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 1rem;
 
}
.vrijemeCVC{    gap: 7px;
display: grid;
grid-template-columns: 1fr 1fr;
}
.vrijemeCVC div input {
    padding-left: 4%;
    height: 56px;
    width: 96%;
    color: black;
}
.vrijemeCVC div{
    display: flex;
    flex-direction: column;   font-size: .8rem;
    font-weight: 600;
    color: #777886;
}
.ukupnoKartice{
    color: #8C9299;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.ukupnaCijena{
    color: #c35d79
    ;
    font-size: 1.1rem;
    font-weight: 600;
}
.footerPlacanje {
    display: grid;
    background-color: white;
    align-content: center;
    /* display: flex; */
    grid-template-columns: 40% 60%;
   
    box-shadow: 0px 4px 12px 0px #00000029;
    border-radius: 7px;
    padding: 0 1rem;
}
.footerPlacanje div{
    display: flex;
    align-items: center;
    gap: 5px;
}

@media screen and (min-width: 777px) {
    .karticaPodaciOglas{
        padding-top: 0rem;
      background-color: #F2F3F5;
    }
  }
  @media screen and (max-width: 850px) {
    .footerPlacanje {
        padding: 1rem 1rem;
    }}
  @media screen and (min-width: 850px) {
    
    .podaciKartice{
        grid-template-columns: 1fr 1fr;
        display: grid;
    }

    .vrijemeCVC{
    
        grid-column: span 2;
    }

    .brKar,
    .imeKar,
    .vrijemeCVC div {

      font-size: 1rem;
    }
    
    .platiKartica {

      font-size: 1.1rem;
    }
    
    .karticaPodaci {
        padding-top: 0;
      display: grid;
      grid-template-columns: 800px;
      justify-content: center;
    }
    .rccs__card{

        width: 24rem !important;
        height: 14rem !important;
    }  
}
@media screen and (min-width: 1250px) {
    .podaciKartice {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        display: grid;
    }
    .karticaPodaci {

      grid-template-columns: 1200px;
    }
    }
    