.razgovori .menuContainer .bigMenu {
  z-index: 900;
}

.razgovori .h1DivDodajOglas {
  display: none;
}
.razgovoriContainer .razgovori {
}
.razgovori {
  margin: 0.9rem;
  padding-bottom: 2rem;
}
.razgovori .porukaComponentes .menuContainer {
  display: none;
}
.showNemaPoruka {
  z-index: 5;
  background-color: aliceblue;
  position: absolute;
  top: 0;
  position: 0 0 0 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  font-size: 1.1rem;
  font-weight: 600;
}
.razgovori .dodajOglasNaslovKategorija {
  background-color: #f2f3f5;
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
  padding: 0.6rem;
  border-radius: 50%;
}
@media screen and (min-width: 800px) {
  .pretraziUser {
  }
}
@media screen and (min-width: 777px) {
  .razgovori .porukaCaskanje {
    place-content: flex-start;
    padding-top: 0;
    height: 100%;
  }
  .razgovori .divTekstPoruke {
    display: grid;

    justify-content: center;
  }

  .razgovori {
    margin: 2rem 0rem 0 0rem;
    background-color: grey;
    padding: 0.9rem;
    background-color: #f2f3f5;
    padding-top: 5rem;
  }
}
@media screen and (min-width: 777px) {
  .razgovori {
    padding-top: 5rem;
  }
  .razgovoriContainer > .tabbar {
    display: none;
  }
  .pretraziUser {
  }
}

@media screen and (min-width: 800px) {
  .razgovori {
    display: grid;
    grid-template-columns: 800px;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .razgovori {
    display: grid;
    grid-template-columns: 1200px;
    justify-content: center;
  }
}
