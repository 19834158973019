.containerAddPostSearch {
  margin: 0 0.9rem;
}
.dodajOglas .LogoutDodajOglas {
  margin: 0;
}
.dodajOglas {
  padding-bottom: 16px;
}
.dodajOglas .h1DivDodajOglas {
  margin: 0.9rem 0;
}
.dodajOglas .butoniPretraga {
  margin: 0.5rem 0;
}

.dodajOglas .cm3Input,
.dodajOglas .hpInput {
  margin-bottom: 0.5rem;
}
.dodajOglas .h1DivDodajOglas,
.dodajOglas .butoniPretraga {
  margin: 0 0.9rem;
}
.automobiliDodajOglasContainer .h1DivDodajOglas,
.automobiliDodajOglasContainer .butoniPretraga {
  margin: 0.5rem 0;
}
.dodajOglasDiv .atributi {
  margin: 0;
}
.dodajOglas .nastavi {
  width: 100%;
}
.dodajOglas div .bigMenu .zamraceniMenu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #393939;
  bottom: 0;
}
.dodajOglasDiv .back {
  margin: 0.9rem 0 0px 0.9rem;
}

.dodajOglasDiv .objasnjenjeDiv {
  margin: 0 0.9rem;
}
.atributi .horizontal-line {
  width: -webkit-fill-available;
  height: 2.5px;
  background-color: #f2f3f5;
  margin: 0.9rem;
}
.atributi .inputiFilter {
  margin: 0 0.9rem;
}
.logoDodajOglas {
  height: 3rem;
  width: 7rem;
  margin: 0.9rem 0 0px 0.9rem;
}
.dodajOglas #buttoniContainer {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 8px;
}
.objasnjenjeP {
  font-size: 0.9rem;
  text-align-last: left;
  color: #6e6e6e;
  padding-bottom: 1rem;
}
.atributi .kmInput,
.atributi .kwInput {
  margin-bottom: 0.5rem;
}

.dodajOglasHeaderContainer {
  display: flex;
  justify-content: space-between;
}
.drzavaGradSelect {
  margin: 0.5rem 0.9rem;
}
.dodajOglas .h1DivDodajOglas {
  display: flex;
  justify-content: space-between;
}
.dodajOglas .h1DivDodajOglas .h1DodajOglas {
  color: #4a4a4a;
  margin: 0rem !important;
}
.koraci {
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.atributi .h1DivDodajOglas {
  display: flex;
  justify-content: space-between;
}
.atributi .h1DivDodajOglas .koraci {
  align-items: start;
}
@media screen and (min-width: 777px) and (max-width: 1000px) {
  .dodajOglasHeaderContainer {
    opacity: 0;
    padding-bottom: 0.5rem;
  }
}
@media screen and (max-width: 777px) {
  .butoniPretraga {
    margin: 0 0.9rem;
  }
  .kategorijePc {
    display: none;
  }
}
@media screen and (min-width: 777px) {
  .automobiliDodajOglasContainer {
    width: 798px;
    margin: auto;
  }
  .tabbar {
    display: none !important;
  }
  .headerDodajOglas {
    grid-column: span 3;
  }
  .dodajOglas .poljoprivredaDodajOglasContainer {
    padding-top: 2.5rem;
  }
  .dodajOglas.automobiliDodajOglasContainer {
  }
  .dodajOglas .dodajOglasNaslovKategorija {
    width: 4rem;
  }
  .dodajOglas .objasnjenjePDodajOglas {
    padding-bottom: 0;
  }
  .dodajOglas .objasnjenjeDivUpload {
    margin: 0.9rem 0 0 0;
  }
  .dodajOglas .dodajOglasDiv .atributi {
    display: flex;
    flex-direction: column;
  }

  .dodajOglas > .tabbar {
    display: none;
  }
}
@media (min-width: 1200px) {
  .automobiliDodajOglasContainer {
    width: 1195px;
  }
  .dodajOglas .headerDodajOglas {
    grid-column: span 4;
  }
  .dodajOglas {
    grid-template-columns: 1200px !important;
  }
}

@media (min-width: 808px) {
  .podaciPcDodajOglas .drzavaGradSelect {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7px;
    margin: 0;
  }

  .dodajOglas {
    grid-template-columns: 808px;
    display: grid;
    justify-content: center;
  }
}
