.logo {
  width: 20rem;
  padding-top: 3rem;
  display: flex;
}
.logoDiv {
  text-align: -webkit-center;
}
.telBr {
  font-weight: 600;
  padding-top: 3rem;
  font-size: 0.8rem;
  padding-bottom: 0.4rem;
}
.pocetnaLogin {
  padding: 0 0.9rem;
}
.telefonskiPodaci {
  height: 2.5rem;
  display: grid;
  gap: 2vw;
  grid-template-columns: 1fr 1fr;
}
.brojTelefona,
.pozivniBr {
  border: none;
  background-color: #f5f5f5;
  border-radius: 1vw;
}
.ulogujSe {
  margin-top: 0.7rem;
  font-size: 0.8rem;
  background-color: #c35d79;
  width: 100%;
  height: 2.5rem;
  display: flex;
  color: white;
  /* text-align: center; */
  place-items: center;
  justify-content: center;
  font-weight: 600;
}
.brojTelefona {
  padding-left: 7px;
}
.pozivniBr {
  padding-left: 4px;
}
.hr {
  border-bottom: 1px solid #e3e5ed;
}
.orDiv {
  align-items: center;
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 40% 20% 40%;
  padding-top: 2rem;
}
.orP {
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
}
.loginWith {
  width: 1.5rem;
}
.socialDiv {
  border-radius: 1vw;
  width: 100%;
  border: 1px solid #e3e5ed;
  gap: 1vw;
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.7rem;
}
.nastaviteSocialP {
  font-size: 0.8rem;
  font-weight: 600;
}
@media screen and (min-width: 850px) {
  .pocetnaLogin {
    width: 800px;
    margin: auto;
  }
}
