.slicniOglasiNaslov {
    margin: 2rem 2rem;
    padding-left: 10px;
    padding-right: 7px;
    border-left: 11px solid #c35d79;
    background-color: white;
    height: 2.5rem;
    transform: skew(-20deg);
    margin-top: 1rem;
    width: -moz-fit-content;
    /* width: auto; */
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding-top: 0;
    place-items: center;
}
.oglas .rasporedOglasa, .oglas .noveObjaveHeader{
    display: none;
}
.oglas .divOglas{
    background-color: white;
}