.nazadBtn,
.menuMessBtn {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  align-self: center;
}
.poruka {
  display: flex;
  align-items: center;
  margin: 0.2rem 0;
  justify-content: space-between;
}
.porukeHeader1 {
  display: flex;
  align-items: center;
  gap: 2vw;
}
.inputPoruka {
  height: 3rem;
  font-size: 0.8rem;
  border-radius: 7px;
  padding-left: 0.5rem;
  color: #5a5a65;
  border: none;
  background-color: #f2f3f5;
}

.porukaCaskanje {
  display: flex;

  margin: 0.9rem;
  flex-direction: column;
  justify-content: space-between;
}
.divTekstPoruke {
  left: 0;
  gap: 1vw;
  margin: 0 0.9rem;
  position: fixed;
  bottom: 0.9rem;
  display: grid;
  width: -webkit-fill-available;
  grid-template-columns: 77% 22%;
  z-index: 999;
}
.sendBtn {
  width: 1.7rem;
}
.divSendBtn {
  border-radius: 7px;
  display: flex;
  background-color: #c35d79;

  align-items: center;
  justify-content: center;
}
.date {
  padding-top: 2rem;
  text-align: center;
  font-size: 0.7rem;
  text-transform: capitalize;
  color: #5a5a65;
  font-weight: 600;
}

.razgovor {
  overflow: hidden;
  justify-items: right;
  display: grid;
  padding-top: 2rem;
  flex-direction: column;
  padding-bottom: 4rem;
  float: right;
  gap: 0.5rem;
}
.poslataPoruka {
  padding: 0.7rem;
  align-items: center;
  display: flex;

  background-color: #c35d78da;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  /* padding: 0.7rem; */
  border-radius: 6vw 6vw 1vw 6vw;
  font-size: 0.8rem;
  float: right;
}
.inboxPoruke {
  padding: 0.7rem;
  align-items: center;
  display: flex;
  background-color: #c35d78da;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  /* padding: 0.7rem; */
  border-radius: 6vw 6vw 6vw 1vw;

  font-size: 0.8rem;
  float: right;
}
.containerRazgovor {
  position: relative;
}
.primljenePoruke {
  display: flex;
  padding-bottom: 5rem;
  clear: inline-end;
}
.menuOpcije {
  display: none;
  font-weight: 600;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: white;
  /* display: none; */
  position: absolute;
  right: 1rem;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  top: 5rem;
  z-index: 5;
  flex-direction: column;
  gap: 3vw;
  padding: 3vw 10vw 3vw 3vw;
  width: 2rem;
  border-radius: 3vw;
}
.menuOpcijeFlex {
  display: flex;
}
.razgovoriContainer .porukaComponentes {
  height: 98%;
  padding: 7px;
}
.profilnaChat {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  object-fit: cover;
}
.pcMess {
  gap: 16px;
  display: grid;
  grid-template-columns: 30% 68%;
}
.pcMess .backBtn {
  display: none;
}
.porukaCaskanje {
  position: relative;
}
.backAndMenu {
  display: flex;
  align-items: center;
  gap: 1vw;
}
.displayFlex .profilnaSlika {
  padding-left: 0px;
  justify-content: center;
}
.displayFlex .imeBroj {
  padding-left: 0px;
}
.profilnaSlika .profilnaChat {
}
.podaciProfil .displayFlex {
  gap: 5px;
}
@media screen and (min-width: 777px) {
  .divTekstPoruke {
    position: absolute;
  }

  .porukaCaskanje {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 800px) {
  .porukaCaskanje {
    padding-top: 5rem;
    position: relative;
    display: grid;
    grid-template-columns: 527px;
    justify-content: center;
  }

  .divTekstPoruke {
    display: grid;

    justify-content: center;
  }
}
