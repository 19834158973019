body {
  margin: 0;
  font-family: CustomMetropolis, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: CustomMetropolis;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
*::-webkit-scrollbar {
  height: 9px;
}
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  margin-top: 7px;
}
*::-webkit-scrollbar-thumb {
  background-color: #c35d79;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
