.nastavi:active {
  color: white;
}
.dodajOglas .containerAddPostSearch .atributi {
  padding-top: 17px;
}
.containerAddPostSearch {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 86;
  background-color: white;
}
.containerAddPostSearch .nastavi {
  margin: 10px 0;
}
.containerAddPostSearch .butoniPretraga {
  margin: 0;
}
@media screen and (min-width: 777px) {
  .dodajOglas .containerAddPostSearch .h1DivDodajOglas {
    margin: 0.9rem 0 !important;
  }
  .dodajOglas .containerAddPostSearch .butoniPretraga {
    margin: 0;
  }
  .dodajOglas .containerAddPostSearch .tabliceContainer {
    margin-top: 0;
  }
}
@media screen and (min-width: 800px) {
  .dodajOglas .containerAddPostSearch .nastavi {
    width: 791px;
    margin: 0.9rem auto;
  }
}
@media screen and (min-width: 1200px) {
  .dodajOglas .containerAddPostSearch .nastavi {
    width: 1190px;
  }
}
