.izaberiPlan .headerAktivni{
    margin: .9rem;
}
.lastStep{
    font-size: .8rem;
    font-weight: 600;
    display: flex;
    align-items: end;
    margin: .9rem;
    justify-content: right;
}
.freePlan{
    display: flex;
    justify-content: space-between;
    margin: 0.9rem;    align-items: center;
    padding: 0 1rem ;
    border: 1px solid #C35D79;
    height: 7rem;
    border-radius: 10px;
    box-shadow: 0px 4px 12px 0px #00000029;

}
.cijenaFree{
    padding-bottom: 0.2rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: #6F2947;
}
.tekstFree{
    font-size: .8rem;
    font-weight: 500;
    color: #c35d79;
}
.choosePlan{
    border: 1px solid #6F2947;




  }.cijenaPlan{
    font-size: 1.3rem;
    font-weight: 600;padding-bottom: 0.5rem;
}
.tekstPay{
    font-size: .8rem;
    padding-bottom: .2rem;
    font-weight: 500;
}
.payPlan{
  background: linear-gradient(to right, #C35D79, #d3889c);
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  margin: 0.9rem;
  border: none;
  border-radius: 10px;
  color: white;
  height: 10rem;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0px 4px 12px 0px #00000029;

}





.checkbox-wrapper-14 .cbx1 {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    }
    .checkbox-wrapper-14 .cbx1 span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    }
    .checkbox-wrapper-14 .cbx1 span:first-child {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid white;
    transition: all 0.2s ease;
    }
    .checkbox-wrapper-14 .cbx1 span:first-child svg {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 6px;
    fill: none;
    stroke: #C35D79;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    }
    .checkbox-wrapper-14 .cbx1 span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: white;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    transition-delay: 0.2s;
    }
    .checkbox-wrapper-14 .cbx1 span:last-child {
    margin-left: 8px;
    }
    .checkbox-wrapper-14 .cbx1 span:last-child:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    height: 1px;
    width: 100%;
    background: #B9B8C3;
    transform-origin: 0 0;
    transform: scaleX(0);
    }
    .checkbox-wrapper-14 .cbx1:hover span:first-child {
    border-color: white;
    }
    
    .checkbox-wrapper-14 .inp-cbx1:checked + .cbx1 span:first-child {
    border-color: white;
    background: rgb(245 245 245);
    animation: check-15 0.6s ease;
    }
    .checkbox-wrapper-14 .inp-cbx1:checked + .cbx1 span:first-child svg {
    stroke-dashoffset: 0;
    }
    .checkbox-wrapper-14 .inp-cbx1:checked + .cbx1 span:first-child:before {
    transform: scale(2.2);
    opacity: 0;
    transition: all 0.6s ease;
    }
    .checkbox-wrapper-14 .inp-cbx1:checked + .cbx1 span:last-child {
    color: #B9B8C3;
    transition: all 0.3s ease;
    }
    .inp-cbx1{
    border-color: white;
    }
    
    @keyframes check-14 {
    50% {
    transform: scale(1.2);
    }
    }
    
    
    














    .checkbox-wrapper-13 .cbx2 {
        -webkit-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        }
        .checkbox-wrapper-13 .cbx2 span {
        display: inline-block;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);
        }
        .checkbox-wrapper-13 .cbx2 span:first-child {
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid white;
        transition: all 0.2s ease;
        }
        .checkbox-wrapper-13 .cbx2 span:first-child svg {
        position: absolute;
        z-index: 1;
        top: 8px;
        left: 6px;
        fill: none;
        stroke: #C35D79;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
        }
        .checkbox-wrapper-13 .cbx2 span:first-child:before {
        content: "";
        width: 100%;
        height: 100%;
        background: white;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
        transition-delay: 0.2s;
        }
        .checkbox-wrapper-13 .cbx2 span:last-child {
        margin-left: 8px;
        }
        .checkbox-wrapper-13 .cbx2 span:last-child:after {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        height: 1px;
        width: 100%;
        background: #B9B8C3;
        transform-origin: 0 0;
        transform: scaleX(0);
        }
        .checkbox-wrapper-13 .cbx2:hover span:first-child {
        border-color: white;
        }
        
        .checkbox-wrapper-13 .inp-cbx2:checked + .cbx2 span:first-child {
        border-color: white;
        background: rgb(245 245 245);
        animation: check-15 0.6s ease;
        }
        .checkbox-wrapper-13 .inp-cbx2:checked + .cbx2 span:first-child svg {
        stroke-dashoffset: 0;
        }
        .checkbox-wrapper-13 .inp-cbx2:checked + .cbx2 span:first-child:before {
        transform: scale(2.2);
        opacity: 0;
        transition: all 0.6s ease;
        }
        .checkbox-wrapper-13 .inp-cbx2:checked + .cbx2 span:last-child {
        color: #B9B8C3;
        transition: all 0.3s ease;
        }
        .inp-cbx2{
        border-color: white;
        }
        
        @keyframes check-13 {
        50% {
        transform: scale(1.2);
        }
        }
        


        
        
        
     
       
       
       
     
       
      
 
       
      
        
        .checkbox-wrapper-15 .cbx,
        .checkbox-wrapper-12 .cbx3  {
          -webkit-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          cursor: pointer;
        }
        .checkbox-wrapper-15 .cbx span,
        .checkbox-wrapper-12 .cbx3 span {
          display: inline-block;
          vertical-align: middle;
          transform: translate3d(0, 0, 0);
        }
        .checkbox-wrapper-15 .cbx span:first-child ,
        .checkbox-wrapper-12 .cbx3 span:first-child {
          position: relative;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          transform: scale(1);
          vertical-align: middle;
          border: 1px solid #C35D79;
          transition: all 0.2s ease;
        }
        .checkbox-wrapper-15 .cbx span:first-child svg,
        .checkbox-wrapper-12 .cbx3 span:first-child svg {
          position: absolute;
          z-index: 1;
          top: 8px;
          left: 6px;
          fill: none;
          stroke: white;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 16px;
          stroke-dashoffset: 16px;
          transition: all 0.3s ease;
          transition-delay: 0.1s;
          transform: translate3d(0, 0, 0);
        }
        .checkbox-wrapper-15 .cbx span:first-child:before , 
        .checkbox-wrapper-12 .cbx3 span:first-child:before{
          content: "";
          width: 100%;
          height: 100%;
          background: #C35D79;
          display: block;
          transform: scale(0);
          opacity: 1;
          border-radius: 50%;
          transition-delay: 0.2s;
        }
        .checkbox-wrapper-15 .cbx span:last-child ,
        .checkbox-wrapper-12 .cbx3 span:last-child{
          margin-left: 8px;
        }
        .checkbox-wrapper-15 .cbx span:last-child:after,
        .checkbox-wrapper-12 .cbx3 span:last-child:after {
          content: "";
          position: absolute;
          top: 8px;
          left: 0;
          height: 1px;
          width: 100%;
          background: #B9B8C3;
          transform-origin: 0 0;
          transform: scaleX(0);
        }
        .checkbox-wrapper-15 .cbx:hover span:first-child,
        .checkbox-wrapper-12 .cbx3:hover span:first-child {
          border-color: #C35D79;
        }
      
        .checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child,
        .checkbox-wrapper-12 .inp-cbx3:checked + .cbx3 span:first-child {
          border-color: #C35D79;
          background: #C35D79;
          animation: check-15 0.6s ease;
        }
        .checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child svg ,
        .checkbox-wrapper-12 .inp-cbx3:checked + .cbx3 span:first-child svg{
          stroke-dashoffset: 0;
        }
        .checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child:before , 
        .checkbox-wrapper-12 .inp-cbx3:checked + .cbx3 span:first-child:before{
          transform: scale(2.2);
          opacity: 0;
          transition: all 0.6s ease;
        }
        .checkbox-wrapper-15 .inp-cbx:checked + .cbx span:last-child,
        .checkbox-wrapper-12 .inp-cbx3:checked + .cbx3 span:last-child {
          color: #B9B8C3;
          transition: all 0.3s ease;
        }
       .inp-cbx,.inp-cbx3{
          border-color: #C35D79;
       }
      
        @keyframes check-15 {
          50% {
            transform: scale(1.2);
          }
        }
      
        @media screen and (min-width: 777px) {
          .izaberiPlan {
            padding-top: 5rem;
          }
        }
        @media screen and (min-width: 800px) {
          .izaberiPlan {
            display: grid;
            grid-template-columns: 800px;justify-content: center;
          }
        }
        @media screen and (min-width: 1200px) {
          .izaberiPlan {
            justify-content: center;
            display: grid;
            grid-template-columns: 1200px;
          }
          .izaberiPlanDivovi{

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .freePlan {
        
            height: 10rem;}
        }
        