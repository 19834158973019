.profilnaInbox {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}
.vrstePoruka {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #f0f3f8;
  justify-content: space-between;
  height: 2rem;
  align-items: center;
  border-radius: 4vw;
  padding: 0.3rem;
  cursor: pointer;
  margin-top: 1.5rem;
}
.InboxPart {
  overflow-y: auto;
  padding: 21px;
  overflow-x: hidden;
}
.InboxPart,
.porukaComponentes {
  background-color: rgb(255, 255, 255);

  border-radius: 20px;
  height: 701px;
}
.noClickedInbox {
  display: flex;
  cursor: pointer;
  height: 2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 3vw;
  font-size: 0.9rem;
  font-weight: 600;
}

.clickedInbox {
  display: flex;
  font-size: 0.9rem;
  font-weight: 600;
  height: 2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 3vw;

  background-color: #c35d79;
}
.inboxContainer {
  overflow-y: auto;
  padding-top: 1rem;
}
.inboxDiv {
  position: relative;
  justify-content: space-between;
  display: flex;
  width: -webkit-fill-available;
}
.imePorukaInboxDiv {
  gap: 12px;
  width: 77%;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;

  /* justify-content: space-between; */
}
.imeInbox {
  font-size: 0.9rem;
  font-weight: 600;
}
.porukaInbox {
  font-size: 0.8rem;
  font-weight: 500;
  color: #919191;
}
.vrijemePoruke {
  font-size: 0.6rem;
  align-self: self-end;
  font-weight: 600;
  color: #919191;
}
.seen {
  display: flex;
  width: 1.2rem;
  align-self: self-end;
}
.vrijemeSeenDiv {
  margin-left: auto;
  gap: 12px;
  align-self: center;
  width: 21%;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  /* justify-content: space-between; */
}
.porukaInbox {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.firstDivMess {
  display: flex;
  overflow: hidden;
  width: 89%;
}
.inboxContainer .hr,
.poslato .hr {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}
.poslato {
  padding-top: 1rem;
}
.brojNovihPoruka {
  align-self: self-end;
  background-color: #c35d79;
  place-content: center;
  padding: 0.4rem;
  font-size: 0.8rem;
  border-radius: 95%;
  width: 0.5rem;
  height: 0.5rem;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.porukaInboxBold {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 600;
  color: #707070;
}
.pretraziUser {
  right: 7px;
  top: 15px;
  position: absolute;
  display: flex;
  width: 77%;
  grid-template-columns: 77% 22%;
}
.inputPretrazi {
  width: 100%;
  height: 3rem;
  font-size: 0.8rem;
  padding-left: 0.5rem;
  border-radius: 32px;
  color: #5a5a65;
  border: none;
  opacity: 1;

  background-color: #f2f3f5;
}

.searchBtn {
  position: absolute;
  right: 1.5%;
  background-color: #c35d79;
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
  padding: 0.6rem;
  border-radius: 50%;
}

.hidePretrazi {
  display: none;
  height: 0;
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.pretraziUser {
  animation: slideInFromRight 0.7s ease-in-out forwards;
}

@media screen and (min-width: 777px) {
  .bodyColor777 {
    background-color: #f2f3f5;
  }
  .pretraziUser {
    right: 0;
    top: 1rem;
    position: absolute;
    display: flex;
    width: 92%;
    grid-template-columns: 77% 22%;
  }
  .containerRazgovor {
    position: relative;
    padding-top: 5rem;
    position: absolute;
    /* top: 5rem; */
    left: 0;
    right: 0;
  }
  .poruka {
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
    justify-content: space-between;
    left: -25px;
  }
}
@media screen and (max-width: 777px) {
  .porukeHeader1 {
    gap: 0;
  }
  .pcOnlyMess {
    display: none;
  }

  .pcMess {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .pretraziUser {
    right: 0;
    top: 1rem;
    position: absolute;
    display: flex;
    width: 77%;
    grid-template-columns: 77% 22%;
  }
}
