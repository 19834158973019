.logOutAtributi .inputiFilter {
  margin: 0 !important;
}
.newImeInput {
  margin-top: 0.5rem;
}
.newImeInput,
.newPrezimeInput {
  width: -webkit-fill-available;
  /* background-color: #f2f3f5; */
  border: 1px solid #e3e5ed;
  border-radius: 1vw;
  height: 2.5rem;
  font-size: 0.9rem;
  color: #5a5a65;
  outline: none;
  font-weight: 600;
  padding-left: 10px;
  margin-bottom: 0.5rem;
}
.logOutAtributi .telefonskiPodaci {
  margin-bottom: 0.5rem;
}
.pozivniBr,
.brojTelefona {
  font-size: 0.9rem;

  font-weight: 600;
}
.LogoutDodajOglas {
  margin: 0.3rem 0.9rem;
  padding: 0.2rem;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.pozivniBr {
  background-color: #c35d79;
  color: white;
}
.headerLogout {
  text-align: center;
  padding-bottom: 0.5rem;
  background-color: #c35d79;
  padding-top: 0.5rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
}
.telefonskiPodaci {
  grid-template-columns: 25% 73%;
  gap: 2%;
}
#ime {
  top: 2px !important;
}
.imePrezimeNoviOglasi {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
  gap: 19px;
}
.pozivniBr,
.brojTelefona {
  background-color: #f2f3f5;
  border-radius: 7px;
  margin-bottom: 0.5rem;
  height: 2.5rem;
  align-items: center;
  padding-left: 10px;
  width: -webkit-fill-available;
  font-size: 0.9rem;
  font-weight: 600;
  color: #5a5a65;
  border: none;
}
@media screen and (min-width: 800px) {
  .logOutAtributi {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    column-gap: 19px;
  }
}

@media screen and (min-width: 1200px) {
  .logOutAtributi {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
